import SearchIcon from '@mui/icons-material/Search';
import type { ListItemButtonProps } from '@mui/material';
import {
  Box,
  InputAdornment,
  ListItem,
  ListItemButton as MUIListItemButton,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ChangeEventHandler, FC, MouseEvent, ReactNode } from 'react';
import { useMemo, useRef } from 'react';
import AutoSizer from 'react-virtualized-auto-sizer';
import { VariableSizeList as List } from 'react-window';
import { Checkbox } from '@/shared/ui';
import { useFilterSelectionControlsContext } from '../FilterSelectionControlsContext/FilterSelectionControlsContext';
import { useShiftSelected } from '../useShiftSelect/useShiftSelect';

const ListItemButton = styled((props: ListItemButtonProps) => {
  return (
    <MUIListItemButton component="label" {...props}>
      <Checkbox
        sx={{ '&.Mui-disabled': { color: 'var(--neutral-600)' } }}
        disabled
        checked={props.selected}
        tabIndex={-1}
        disableRipple
      />
      {props.children}
    </MUIListItemButton>
  );
})(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1.25),
  lineHeight: '22px',
  fontSize: '14px',
  padding: `${theme.spacing(1)} ${theme.spacing(2)}`,
  color: 'var(--neutral-700)',
  marginBottom: theme.spacing(0.75),

  '&.Mui-selected': {
    backgroundColor: 'var(--accent-100)',
    fontWeight: 500,
    color: theme.palette.text.primary,
  },

  '&:hover, &:focus-visible': {
    backgroundColor: 'var(--accent-200)',

    '& .MuiCheckbox-root': {
      color: theme.palette.primary.light,
    },
  },
}));

export interface ItemRendererProps {
  value: unknown;
  setHeight: (height: number) => void;
}

interface Props {
  filtersSlot?: ReactNode;
  ItemRenderer: FC<ItemRendererProps>;
  value?: string[];
  onChange: (v: string[]) => void;
  optionsSortComparator?: (a: string, b: string) => number;
}

export function VirtualizedFilter({
  ItemRenderer,
  value,
  filtersSlot,
  onChange,
  optionsSortComparator,
}: Props) {
  const { filteredOptions, filterQuery, setFilterQuery, getOptionValue } =
    useFilterSelectionControlsContext();
  const preparedOptions = useMemo(() => {
    if (!optionsSortComparator) {
      return filteredOptions;
    }

    return filteredOptions.map(getOptionValue).toSorted(optionsSortComparator);
  }, [filteredOptions, getOptionValue, optionsSortComparator]);
  const onShiftChange = useShiftSelected({
    options: filteredOptions as string[],
    changeHandler: (addOrRemove, options) => {
      if (addOrRemove) {
        onChange([...new Set([...(value || []), ...options])]);
      } else {
        onChange([...new Set(value || []).difference(new Set(options))]);
      }
    },
    getOptionValue,
  });
  const listRef = useRef<List>(null);
  const optionHeights = useRef<Record<number, number>>({});

  const handleFilterQueryChange: ChangeEventHandler<HTMLInputElement> = (
    event,
  ) => {
    setFilterQuery(event.target.value);
  };
  const handleOptionToggle =
    (option: string, checked: boolean) => (event: MouseEvent) => {
      onShiftChange(option, checked, event.shiftKey);
    };
  const handleSetOptionHeight = (index: number, height: number) => {
    const wrapperOffset = 8 + 8 + 6; // paddingTop + paddingBottom + marginBottom

    listRef.current?.resetAfterIndex(0);
    optionHeights.current = {
      ...optionHeights.current,
      [index]: height + wrapperOffset,
    };
  };
  const getOptionHeight = (index: number) => {
    const ITEM_HEIGHT = 46;

    return optionHeights.current[index] || ITEM_HEIGHT;
  };

  let content: ReactNode = (
    <Typography
      sx={{
        py: 2,
      }}
      color="secondary"
      textAlign="center"
      variant="modalContent"
    >
      No values found
    </Typography>
  );

  if (preparedOptions.length) {
    const totalHeight = preparedOptions.reduce<number>((acc, _curr, ind) => {
      return acc + getOptionHeight(ind);
    }, 0);

    content = (
      <Box
        sx={{
          maxHeight: 'min(500px, 40vh)',
          height: totalHeight,
        }}
      >
        <AutoSizer>
          {({ height, width }) => (
            <List
              ref={listRef}
              height={height}
              width={width}
              itemCount={preparedOptions.length}
              itemSize={getOptionHeight}
            >
              {({ style, index }) => {
                const option = preparedOptions[index];
                const optionValue = getOptionValue(option);
                const checked = (value || []).includes(optionValue);

                return (
                  <ListItem key={optionValue} disablePadding style={style}>
                    <ListItemButton
                      selected={checked}
                      onClick={handleOptionToggle(optionValue, !checked)}
                    >
                      <ItemRenderer
                        value={option}
                        setHeight={(height) =>
                          handleSetOptionHeight(index, height)
                        }
                      />
                    </ListItemButton>
                  </ListItem>
                );
              }}
            </List>
          )}
        </AutoSizer>
      </Box>
    );
  }

  return (
    <div>
      {filtersSlot}
      <Box mb={2}>
        <OutlinedInput
          sx={{
            width: '100%',
            backgroundColor: 'white',
          }}
          type="search"
          size="small"
          startAdornment={
            <InputAdornment position="start">
              <SearchIcon />
            </InputAdornment>
          }
          placeholder="Search"
          value={filterQuery}
          onChange={handleFilterQueryChange}
        />
      </Box>
      {content}
    </div>
  );
}
