import type { SvgIconProps } from '@mui/material';
import { SvgIcon } from '@mui/material';

export function DefaultWidthIcon(props: SvgIconProps) {
  return (
    <SvgIcon {...props}>
      <svg
        viewBox="0 0 24 24"
        fill="currentColor"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path d="M2 15.5v2h20v-2zm0-10v2h20v-2z"></path>
      </svg>
    </SvgIcon>
  );
}
