import AccountCircleIcon from '@mui/icons-material/AccountCircle';
import CampaignIcon from '@mui/icons-material/Campaign';
import PasswordIcon from '@mui/icons-material/KeyRounded';
import LogoutIcon from '@mui/icons-material/LogoutRounded';
import {
  Divider,
  IconButton as MUIIconButton,
  Menu,
  MenuItem as MUIMenuItem,
  Tooltip,
  Card,
  dividerClasses,
  ListItemIcon,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import { useRef, useState } from 'react';
import { Link } from 'react-router-dom';
import { ChangePasswordDialog } from '@/features/changePassword';
import { Features, useFeatureFlags } from '@/features/featureFlags';
import { logout, selectEmail } from '@/entities/session';
import { ROUTES } from '@/shared/constants';
import { Monitoring } from '@/shared/lib';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { DatasourcesIcon } from '@/shared/ui';

const NO_EMAIL_PLACEHOLDER = 'Unknow user';

const IconButton = styled(MUIIconButton)(() => ({
  height: '100%',
  aspectRatio: '1 / 1',
  borderRadius: 0,
  color: 'white',

  '&:hover': {
    backgroundColor: 'var(--accent-800)',
  },
}));
const MenuItem = styled(MUIMenuItem)(({ theme }) => ({
  fontSize: '14px',
  padding: `${theme.spacing(1)} ${theme.spacing(1.5)}`,

  [`+.${dividerClasses.root}`]: {
    margin: 0,
  },
})) as typeof MUIMenuItem;

export function ProfileMenu() {
  const dispatch = useAppDispatch();
  const email = useAppSelector(selectEmail);
  const { isFeatureAvailable } = useFeatureFlags();
  const anchorRef = useRef(null);
  const [menuOpen, setMenuOpen] = useState(false);
  const [changePasswordDialogOpen, setChangePasswordDialogOpen] =
    useState(false);

  const toggleMenu = (newValue: boolean) => () => {
    setMenuOpen(newValue);
  };
  const toggleChangePasswordOpen = (newValue: boolean) => () => {
    setChangePasswordDialogOpen(newValue);
  };
  const showReportDialog = () => {
    Monitoring.showReportDialog();
  };
  const handleLogout = () => {
    dispatch(logout());
  };

  return (
    <>
      <Tooltip title="Account settings">
        <IconButton
          ref={anchorRef}
          size="large"
          onClick={toggleMenu(!menuOpen)}
        >
          <AccountCircleIcon fontSize="large" />
        </IconButton>
      </Tooltip>
      <Menu
        MenuListProps={{
          sx: {
            p: 1,
          },
        }}
        slotProps={{
          paper: {
            sx: {
              borderRadius: 3,
            },
          },
        }}
        anchorEl={anchorRef.current}
        open={menuOpen}
        onClose={toggleMenu(false)}
        onClick={toggleMenu(false)}
      >
        <Card
          elevation={0}
          sx={{
            px: 2,
            py: 3,
            mb: 2,
            borderRadius: 2,
            fontSize: '0.875rem',
            color: 'text.secondary',
            bgcolor: 'var(--neutral-100)',
          }}
        >
          {email || NO_EMAIL_PLACEHOLDER}
        </Card>
        {isFeatureAvailable(Features.datasources) && (
          <MenuItem component={Link} to={ROUTES.datasources}>
            <ListItemIcon>
              <DatasourcesIcon />
            </ListItemIcon>
            Data sources
          </MenuItem>
        )}
        <MenuItem onClick={toggleChangePasswordOpen(true)}>
          <ListItemIcon>
            <PasswordIcon />
          </ListItemIcon>
          Change password
        </MenuItem>
        <MenuItem onClick={showReportDialog}>
          <ListItemIcon>
            <CampaignIcon />
          </ListItemIcon>
          Report feedback
        </MenuItem>
        <Divider />
        <MenuItem onClick={handleLogout}>
          <ListItemIcon>
            <LogoutIcon />
          </ListItemIcon>
          Logout
        </MenuItem>
      </Menu>
      <ChangePasswordDialog
        open={changePasswordDialogOpen}
        onClose={toggleChangePasswordOpen(false)}
      />
    </>
  );
}
