import { createSelector } from '@reduxjs/toolkit';
import type { MetricColoring } from '@/features/DataGridColoring';
import { DataGridViewType } from '@/features/DataGridTypeSwitcher';
import type { DownloadData } from '@/features/DownloadData';
import type {
  Filters,
  NumberFilterParams,
  StringFilterParams,
} from '@/features/filters';
import { getApplicableFilters, prepareFilters } from '@/features/filters';
import type { ColumnsMeta } from '@/entities/columnsConfig';
import { prepareDateRangeValueObject } from '@/shared/api';
import { DEFAULT_PAGE_SIZE, RowHeights } from '@/shared/constants';
import { DataStatus } from '@/shared/lib/dataFetching';
import type { AppThunk } from '@/shared/model';
import { createAppSlice } from '@/shared/model';
import { DateRangePresets, DateScale, Lifetime } from '@/shared/types';
import type {
  SerializedDateRangeValueObject,
  Sorting,
  SortingItem,
} from '@/shared/types';
import type { MinMaxData, TotalData } from '@/shared/ui';
import * as CohortMarketingPerformanceAPI from '../api/cohortMarketingPerformanceAPI';
import type { ParentGroup, TreeDataRow } from '../api/types';
import type {
  DataConfig,
  GetDataParams,
  GetMetricsMinMaxParams,
  GetTotalParams,
  MarketingPerformanceDataPage,
} from './types';

export interface State {
  meta: {
    status: DataStatus;
    data: ColumnsMeta;
  };
  config: DataConfig;
}

const initialState: State = {
  meta: {
    status: DataStatus.idle,
    data: {
      defaultColumns: { dimensions: [], metrics: [] },
      config: {},
      dimensions: [],
      metrics: [],
    },
  },
  config: {
    dimensions: [],
    metrics: [],
    dateScale: DateScale.DAY,
    dateRange: { preset: DateRangePresets.LAST_30_DAYS },
    sorting: [],
    probabilisticAttribution: true,
    lifetime: Lifetime.YEAR,
    filters: [],
    rowHeight: RowHeights.normal,
    columnsWidth: {},
    metricsColoring: [],
    dataGridViewType: DataGridViewType.dataTable,
  },
};

export const cohortMarketingPerformanceSlice = createAppSlice({
  name: 'cohortMarketingPerformance',
  initialState,
  reducers: (create) => ({
    reset: create.reducer(() => {
      return { ...initialState };
    }),
    setupColumnsMeta: create.asyncThunk(
      (_: void) => {
        return CohortMarketingPerformanceAPI.getColumnsMeta();
      },
      {
        pending: (state) => {
          state.meta.status = DataStatus.loading;
        },
        fulfilled: (state, action) => {
          state.meta.data = action.payload;
          state.meta.status = DataStatus.finished;
        },
        rejected: (state) => {
          state.meta.status = DataStatus.error;
        },
      },
    ),
    setDefaultColumns: create.reducer((state) => {
      state.config.dimensions = state.meta.data.defaultColumns.dimensions;
      state.config.metrics = state.meta.data.defaultColumns.metrics;
      state.config.sorting = [];
      state.config.metricsColoring = [];
    }),
    updateConfigRowHeight: create.reducer<number>((state, action) => {
      state.config.rowHeight = action.payload;
    }),
    updateColumns: create.reducer<{ dimensions: string[]; metrics: string[] }>(
      (state, action) => {
        const allowedColumns = new Set([
          ...action.payload.dimensions,
          ...action.payload.metrics,
        ]);
        const filteredSorting = state.config.sorting.filter((sortItem) => {
          return allowedColumns.has(sortItem.name);
        });
        const filteredMetricsColoring = (
          state.config.metricsColoring || []
        ).filter((metricColoring) => {
          return allowedColumns.has(metricColoring.metric);
        });

        if (state.config.sorting.length !== filteredSorting.length) {
          state.config.sorting = filteredSorting;
        }

        if (
          state.config.metricsColoring?.length !==
          filteredMetricsColoring.length
        ) {
          state.config.metricsColoring = filteredMetricsColoring;
        }

        state.config.dimensions = action.payload.dimensions;
        state.config.metrics = action.payload.metrics;
      },
    ),
    updateConfigMetricsColoring: create.reducer<MetricColoring[]>(
      (state, action) => {
        state.config.metricsColoring = action.payload;
      },
    ),
    reorderDimensions: create.reducer<{
      id: string;
      oldIndex: number;
      newIndex: number;
    }>((state, action) => {
      const newDimensions = state.config.dimensions.toSpliced(
        action.payload.oldIndex,
        1,
      );

      newDimensions.splice(action.payload.newIndex, 0, action.payload.id);
      state.config.dimensions = newDimensions;
    }),
    reorderMetrics: create.reducer<{
      id: string;
      oldIndex: number;
      newIndex: number;
    }>((state, action) => {
      const newMetrics = state.config.metrics.toSpliced(
        action.payload.oldIndex,
        1,
      );

      newMetrics.splice(action.payload.newIndex, 0, action.payload.id);
      state.config.metrics = newMetrics;
    }),
    updateSorting: create.reducer<{ column: string; item?: SortingItem }>(
      (state, action) => {
        if (!action.payload.item) {
          state.config.sorting = state.config.sorting.filter((item) => {
            return item.name !== action.payload.column;
          });
        } else {
          const index = state.config.sorting.findIndex((item) => {
            return item.name === action.payload.column;
          });

          if (index !== -1) {
            state.config.sorting[index] = action.payload.item;
          } else {
            state.config.sorting.push(action.payload.item);
          }
        }
      },
    ),
    updateDateScale: create.reducer<DateScale>((state, action) => {
      state.config.dateScale = action.payload;
    }),
    updateDateRange: create.reducer<SerializedDateRangeValueObject>(
      (state, action) => {
        state.config.dateRange = action.payload;
      },
    ),
    updateProbabilisticAttribution: create.reducer<boolean>((state, action) => {
      state.config.probabilisticAttribution = action.payload;
    }),
    updateLifetime: create.reducer<Lifetime>((state, action) => {
      state.config.lifetime = action.payload;
    }),
    updateDataGridViewType: create.reducer<DataGridViewType>(
      (state, action) => {
        state.config.dataGridViewType = action.payload;
      },
    ),
    updateFilters: create.reducer<Filters>((state, action) => {
      state.config.filters = action.payload;
    }),
    resetFilters: create.reducer((state) => {
      state.config.filters = [];
    }),
    resetConfig: create.reducer((state) => {
      state.config = { ...initialState.config };
    }),
    updateConfig: create.reducer<DataConfig>((state, action) => {
      state.config = action.payload;
    }),
    updateConfigColumnsWidth: create.reducer<{ name: string; width?: number }>(
      (state, action) => {
        state.config.columnsWidth = {
          ...state.config.columnsWidth,
          [action.payload.name]: action.payload.width,
        };
      },
    ),
  }),
  selectors: {
    selectAdvaceSortableOptions: createSelector(
      (sliceState: State) => sliceState.meta.data.config,
      (sliceState: State) => sliceState.config.metrics,
      (config, metrics) => {
        return metrics
          .filter((metric) => {
            return config[metric].isAdvancedSortable;
          })
          .map((metric) => {
            return {
              name: config[metric].name,
              value: metric,
            };
          });
      },
    ),
    selectMetaIsLoading: (state) => {
      return state.meta.status === DataStatus.loading;
    },
    selectMetaIsFailed: (state) => {
      return state.meta.status === DataStatus.error;
    },
    selectMetaIsLoaded: (state) => {
      return state.meta.status === DataStatus.finished;
    },
    selectMetaConfig: (state) => {
      return state.meta.data.config;
    },
    selectMetaAllDimensions: (state) => {
      return state.meta.data.dimensions;
    },
    selectMetaAllMetrics: (state) => {
      return state.meta.data.metrics;
    },
    selectMetaDefaultColumns: (state) => {
      return state.meta.data.defaultColumns;
    },
    selectConfig: (state) => {
      return state.config;
    },
    selectConfigMetrics: (state) => {
      return state.config.metrics;
    },
    selectConfigDimensions: (state) => {
      return state.config.dimensions;
    },
    selectConfigSorting: (state) => {
      return state.config.sorting;
    },
    selectDateScale: (state) => {
      return state.config.dateScale;
    },
    selectDateRange: (state) => {
      return state.config.dateRange;
    },
    selectProbabilisticAttribution: (state) => {
      return state.config.probabilisticAttribution;
    },
    selectLifetime: (state) => {
      return state.config.lifetime;
    },
    selectFilters: (state) => {
      return state.config.filters;
    },
    selectConfigRowHeight: (state) => {
      return state.config.rowHeight;
    },
    selectConfigColumnsWidth: (state) => {
      return state.config.columnsWidth;
    },
    selectConfigDataGridViewType: (state) => {
      return state.config.dataGridViewType;
    },
    selectIsTreeDataGridView: (state) => {
      return state.config.dataGridViewType === DataGridViewType.treeDataGrid;
    },
    selectConfigMetricsColoring: (state) => {
      return state.config.metricsColoring || [];
    },
  },
});

export const setupColumnsMeta = (): AppThunk => (dispatch) => {
  dispatch(cohortMarketingPerformanceSlice.actions.setupColumnsMeta()).then(
    () => {
      dispatch(cohortMarketingPerformanceSlice.actions.setDefaultColumns());
    },
  );
};

export const resetConfig = (): AppThunk => (dispatch) => {
  dispatch(cohortMarketingPerformanceSlice.actions.resetConfig());
  dispatch(cohortMarketingPerformanceSlice.actions.setDefaultColumns());
};

interface DownloadDataParams {
  metrics: string[];
  dimensions: string[];
  dateScale: DateScale;
  dateRange: SerializedDateRangeValueObject;
  sorting: Sorting;
  probabilisticAttribution: boolean;
  lifetime: number;
  filters: Filters;
  allMetrics: string[];
  allDimensions: string[];
}

export const downloadData =
  (params: DownloadDataParams): AppThunk<Promise<DownloadData>> =>
  async (_dispatch) => {
    const DOWNLOAD_PAGE_SIZE = 1000000000;
    const { dimensionsFilters, metricsFilters } = prepareFilters(
      params.filters,
      params.allMetrics,
      params.allDimensions,
    );

    return Promise.all([
      CohortMarketingPerformanceAPI.getTotal({
        dimensions: params.dimensions,
        metrics: params.metrics,
        dimensionsFilters,
        dateRange: prepareDateRangeValueObject(params.dateRange),
        probabilisticAttribution: params.probabilisticAttribution,
        lifetime: params.lifetime,
      }),
      CohortMarketingPerformanceAPI.getData({
        dimensions: params.dimensions,
        metrics: params.metrics,
        dateScale: params.dateScale,
        offset: 0,
        dateRange: prepareDateRangeValueObject(params.dateRange),
        sort: params.sorting,
        probabilisticAttribution: params.probabilisticAttribution,
        lifetime: params.lifetime,
        dimensionsFilters,
        metricsFilters,
        pageSize: DOWNLOAD_PAGE_SIZE,
      }),
    ]).then(([totalResponse, rowsResponse]) => {
      return {
        rows: rowsResponse.rows,
        total: totalResponse,
      };
    });
  };

export const getPage =
  (params: GetDataParams): AppThunk<Promise<MarketingPerformanceDataPage>> =>
  (_dispatch, getState) => {
    const state = getState();
    // moved here to prevent new fetcher generation on metric column delete
    const metrics = selectConfigMetrics(state);
    const allDimensions = selectMetaAllDimensions(state);
    const allMetrics = selectMetaAllMetrics(state);
    const { dimensionsFilters, metricsFilters } = prepareFilters(
      params.filters,
      allMetrics,
      allDimensions,
    );

    return CohortMarketingPerformanceAPI.getData({
      dimensions: params.dimensions,
      metrics,
      dateScale: params.dateScale,
      dateRange: prepareDateRangeValueObject(params.dateRange),
      offset: params.offset || 0,
      sort: params.sorting,
      probabilisticAttribution: params.probabilisticAttribution,
      lifetime: params.lifetime,
      dimensionsFilters,
      metricsFilters,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

export const getTotalData =
  (params: GetTotalParams): AppThunk<Promise<TotalData>> =>
  (_dispatch, getState) => {
    if (params.dimensions.length === 0 && params.metrics.length === 0) {
      return Promise.resolve({});
    }

    const state = getState();
    const allDimensions = selectMetaAllDimensions(state);
    const allMetrics = selectMetaAllMetrics(state);
    const { dimensionsFilters } = prepareFilters(
      params.filters,
      allMetrics,
      allDimensions,
    );

    return CohortMarketingPerformanceAPI.getTotal({
      dimensions: params.dimensions,
      metrics: params.metrics,
      dateRange: prepareDateRangeValueObject(params.dateRange),
      probabilisticAttribution: params.probabilisticAttribution,
      lifetime: params.lifetime,
      dimensionsFilters,
    });
  };

export const getTreeData =
  (parentGroups: ParentGroup[]): AppThunk<Promise<TreeDataRow[]>> =>
  (_dispatch, getState) => {
    const state = getState();
    const config = selectConfig(state);
    const sorting = selectConfigSorting(state);
    const filters = selectFilters(state);
    const allDimensions = selectMetaAllDimensions(state);
    const allMetrics = selectMetaAllMetrics(state);
    const { dimensionsFilters, metricsFilters } = prepareFilters(
      filters,
      allMetrics,
      allDimensions,
    );

    return CohortMarketingPerformanceAPI.getTreeData({
      dimensions: [config.dimensions[parentGroups.length]],
      metrics: config.metrics,
      dateScale: config.dateScale,
      dateRange: prepareDateRangeValueObject(config.dateRange),
      sort: sorting,
      probabilisticAttribution: config.probabilisticAttribution,
      lifetime: config.lifetime,
      dimensionsFilters,
      metricsFilters,
      parentGroups: parentGroups,
    }).then((response) => {
      return response.rows.map((row) => {
        return {
          ...row,
          path: [
            ...parentGroups.map((parentGroup) => {
              return parentGroup.value;
            }),
            row[config.dimensions[parentGroups.length]],
          ],
        } as TreeDataRow;
      });
    });
  };

export const getMetricsMinMax =
  (params: GetMetricsMinMaxParams): AppThunk<Promise<MinMaxData>> =>
  (_dispatch, getState) => {
    if (params.dimensions.length === 0 && params.metrics.length === 0) {
      return Promise.resolve({});
    }

    const state = getState();
    const isTreeView = selectIsTreeDataGridView(state);
    const allDimensions = selectMetaAllDimensions(state);
    const allMetrics = selectMetaAllMetrics(state);
    const { dimensionsFilters, metricsFilters } = prepareFilters(
      params.filters,
      allMetrics,
      allDimensions,
    );

    return CohortMarketingPerformanceAPI.getMetricsMinMax({
      metricNames: params.metrics,
      selectedDimensions: params.dimensions,
      dimensionsFilters,
      metricsFilters,
      dateRange: prepareDateRangeValueObject(params.dateRange),
      dateScale: params.dateScale,
      probabilisticAttribution: params.probabilisticAttribution,
      lifetime: params.lifetime,
      groupedData: isTreeView,
    });
  };

export const getDataUpdateInfo = () => () => {
  return CohortMarketingPerformanceAPI.getDataUpdateInfo();
};

interface GetNumberFilterParams {
  filterName: string;
  dimensions: string[];
  filters: Filters;
  allMetrics: string[];
  allDimensions: string[];
  dateRange: SerializedDateRangeValueObject;
  dateScale: DateScale;
  probabilisticAttribution: boolean;
  lifetime: number;
}

export const getNumberFilterParams =
  (params: GetNumberFilterParams): AppThunk<Promise<NumberFilterParams>> =>
  (_dispatch, getState) => {
    const state = getState();
    const isTreeView = selectIsTreeDataGridView(state);
    const { dimensionsFilters, metricsFilters } = prepareFilters(
      getApplicableFilters(params.filters, params.filterName),
      params.allMetrics,
      params.allDimensions,
    );

    return CohortMarketingPerformanceAPI.getNumberFilterParams({
      dimensions: params.dimensions,
      filterName: params.filterName,
      dimensionsFilters,
      metricsFilters,
      dateRange: prepareDateRangeValueObject(params.dateRange),
      dateScale: params.dateScale,
      probabilisticAttribution: params.probabilisticAttribution,
      lifetime: params.lifetime,
      groupedData: isTreeView,
    });
  };

interface GetStringFilterParams {
  filterName: string;
  filters: Filters;
  allMetrics: string[];
  allDimensions: string[];
  dateRange: SerializedDateRangeValueObject;
}

export const getStringFilterParams =
  (params: GetStringFilterParams): AppThunk<Promise<StringFilterParams>> =>
  () => {
    const { dimensionsFilters } = prepareFilters(
      getApplicableFilters(params.filters, params.filterName),
      params.allMetrics,
      params.allDimensions,
    );

    return CohortMarketingPerformanceAPI.getStringFilterParams({
      filterName: params.filterName,
      dimensionsFilters,
      dateRange: prepareDateRangeValueObject(params.dateRange),
    });
  };

export const deleteConfigDimension =
  (dimension: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const config = selectConfig(state);

    dispatch(
      updateColumns({
        dimensions: config.dimensions.filter((item) => {
          return item !== dimension;
        }),
        metrics: config.metrics,
      }),
    );
  };

export const deleteConfigMetric =
  (metric: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const config = selectConfig(state);

    dispatch(
      updateColumns({
        dimensions: config.dimensions,
        metrics: config.metrics.filter((item) => {
          return item !== metric;
        }),
      }),
    );
  };

export const {
  reorderDimensions,
  reorderMetrics,
  updateSorting,
  updateColumns,
  updateProbabilisticAttribution,
  updateDateScale,
  updateDateRange,
  updateLifetime,
  updateFilters,
  resetFilters,
  updateConfig,
  reset,
  updateConfigRowHeight,
  updateConfigColumnsWidth,
  updateDataGridViewType,
  updateConfigMetricsColoring,
} = cohortMarketingPerformanceSlice.actions;
export const {
  selectConfigDimensions,
  selectConfigMetrics,
  selectConfigSorting,
  selectProbabilisticAttribution,
  selectDateScale,
  selectLifetime,
  selectFilters,
  selectConfig,
  selectMetaIsLoading,
  selectMetaIsFailed,
  selectMetaIsLoaded,
  selectMetaConfig,
  selectMetaAllDimensions,
  selectMetaAllMetrics,
  selectMetaDefaultColumns,
  selectConfigRowHeight,
  selectConfigColumnsWidth,
  selectDateRange,
  selectAdvaceSortableOptions,
  selectConfigDataGridViewType,
  selectIsTreeDataGridView,
  selectConfigMetricsColoring,
} = cohortMarketingPerformanceSlice.selectors;
