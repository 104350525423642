import { Box, Divider } from '@mui/material';
import { useCallback } from 'react';
import {
  selectConfigDataGridViewType,
  selectConfigDimensions,
  selectConfigMetrics,
  selectConfigMetricsColoring,
  selectConfigRowHeight,
  selectMetaAllDimensions,
  selectMetaAllMetrics,
  selectMetaConfig,
  selectMetaIsLoading,
  selectProbabilisticAttribution,
  setupColumnsMeta,
  updateColumns,
  updateConfigRowHeight,
  updateDataGridViewType,
  updateProbabilisticAttribution,
} from '@/widgets/calendarInsights';
import { AddColumns } from '@/features/AddColumns';
import { DataGridAutosize } from '@/features/DataGridAutosize';
import type { MetricColoring } from '@/features/DataGridColoring';
import { DataGridColoring } from '@/features/DataGridColoring';
import type { DataGridViewType } from '@/features/DataGridTypeSwitcher';
import { DataGridTypeSwitcher } from '@/features/DataGridTypeSwitcher';
import { ProbabilisticAttributionSwitcher } from '@/features/ProbabilisticAttribution';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import type { MinMaxData } from '@/shared/ui';
import { DensitySwitcher } from '@/shared/ui';
import {
  selectIsTreeDataGridView,
  updateConfigMetricsColoring,
} from '../../model/slice';

interface Props {
  minMax?: MinMaxData;
}

export function CalendarInsightsToolbar({ minMax }: Props) {
  const dispatch = useAppDispatch();
  const probabilisticAttribution = useAppSelector(
    selectProbabilisticAttribution,
  );
  const isColumnsConfigLoading = useAppSelector(selectMetaIsLoading);
  const selectedDimensions = useAppSelector(selectConfigDimensions);
  const selectedMetrics = useAppSelector(selectConfigMetrics);
  const allDimensions = useAppSelector(selectMetaAllDimensions);
  const allMetrics = useAppSelector(selectMetaAllMetrics);
  const columnsConfig = useAppSelector(selectMetaConfig);
  const rowHeight = useAppSelector(selectConfigRowHeight);
  const metricsColoring = useAppSelector(selectConfigMetricsColoring);
  const dataGridViewType = useAppSelector(selectConfigDataGridViewType);
  const isTreeDataGridView = useAppSelector(selectIsTreeDataGridView);

  const handleColumnsConfigRequest = useCallback(() => {
    dispatch(setupColumnsMeta());
  }, [dispatch]);
  const handleRowHeightChange = (size: number) => {
    dispatch(updateConfigRowHeight(size));
  };
  const handleColumnsUpdate = (newColumns: {
    dimensions: string[];
    metrics: string[];
  }) => {
    dispatch(updateColumns(newColumns));
  };
  const handleProbabilisticAttributionChange = (checked: boolean) => {
    dispatch(updateProbabilisticAttribution(checked));
  };
  const handleDataGridViewTypeChange = (newViewType: DataGridViewType) => {
    dispatch(updateDataGridViewType(newViewType));
  };
  const handleMetricsColoringChange = (
    newMetricsColoring: MetricColoring[],
  ) => {
    dispatch(updateConfigMetricsColoring(newMetricsColoring));
  };

  return (
    <Box display="flex" gap={2}>
      <AddColumns
        isColumnsConfigLoading={isColumnsConfigLoading}
        selectedDimensions={selectedDimensions}
        selectedMetrics={selectedMetrics}
        allDimensions={allDimensions}
        allMetrics={allMetrics}
        columnsConfig={columnsConfig}
        requestColumnsConfig={handleColumnsConfigRequest}
        updateColumns={handleColumnsUpdate}
      />
      <Divider flexItem orientation="vertical" />
      <ProbabilisticAttributionSwitcher
        value={probabilisticAttribution}
        onChange={handleProbabilisticAttributionChange}
      />
      <Box flexGrow={1} />
      <DataGridTypeSwitcher
        isTreeViewAvailable={selectedDimensions.length > 1}
        value={dataGridViewType}
        onChange={handleDataGridViewTypeChange}
      />
      <Divider flexItem orientation="vertical" />
      <DensitySwitcher value={rowHeight} onChange={handleRowHeightChange} />
      <Divider flexItem orientation="vertical" />
      {!isTreeDataGridView && (
        <>
          <DataGridAutosize />
          <Divider flexItem orientation="vertical" />
        </>
      )}
      <DataGridColoring
        columnsConfig={columnsConfig}
        minMax={minMax}
        availableMetrics={selectedMetrics}
        metricsColoring={metricsColoring}
        onMetricsColoringChange={handleMetricsColoringChange}
      />
    </Box>
  );
}
