import { DataStatus } from '@/shared/lib/dataFetching';
import type { AppThunk } from '@/shared/model';
import { createAppSlice } from '@/shared/model';
import * as SavedViewsAPI from '../api/savedViewsAPI';
import type { SavedViewStorage } from '../api/types';
import type { SavedViewRecord } from './types';

interface State {
  views: SavedViewRecord[];
  status: DataStatus;
}

const initialState: State = {
  views: [],
  status: DataStatus.idle,
};

export const savedViewsSlice = createAppSlice({
  name: 'savedViews',
  initialState,
  reducers: (create) => ({
    setupViews: create.asyncThunk(
      (_: void) => {
        return SavedViewsAPI.getSavedViews();
      },
      {
        pending: (state) => {
          state.status = DataStatus.loading;
        },
        fulfilled: (state, action) => {
          state.views = action.payload;
          state.status = DataStatus.finished;
        },
        rejected: (state) => {
          state.status = DataStatus.error;
        },
      },
    ),
    createView: create.asyncThunk<
      {
        storage: SavedViewStorage;
        name: string;
        snapshot: unknown;
      },
      SavedViewRecord
    >(
      ({ storage, name, snapshot }) => {
        return SavedViewsAPI.createSavedView(storage, name, snapshot);
      },
      {
        fulfilled: (state, action) => {
          state.views.unshift(action.payload);
        },
      },
    ),
    updateView: create.asyncThunk<
      {
        uuid: string;
        update: Partial<SavedViewRecord['savedView']>;
        hiddenUpdate?: boolean;
      },
      SavedViewRecord
    >(
      ({ uuid, update }, { getState }) => {
        const state = getState() as RootState;
        const views: SavedViewRecord[] = selectViews(state);
        const viewToUpdate = views.find((view) => {
          return view.uuid === uuid;
        });

        if (!viewToUpdate) {
          throw new Error(`Update of unknown view: ${uuid}`);
        }

        return SavedViewsAPI.updateSavedView(uuid, {
          ...viewToUpdate.savedView,
          ...update,
        });
      },
      {
        pending: (state, action) => {
          state.views = state.views.map((view) => {
            if (view.uuid === action.meta.arg.uuid) {
              return {
                ...view,
                updating: !action.meta.arg.hiddenUpdate,
              };
            }

            return view;
          });
        },
        fulfilled: (state, action) => {
          state.views = state.views.map((view) => {
            if (view.uuid === action.payload.uuid) {
              return action.payload;
            }

            return view;
          });
        },
      },
    ),
    deleteView: create.asyncThunk(
      (uuid: string) => {
        return SavedViewsAPI.deleteSavedView(uuid);
      },
      {
        fulfilled: (state, action) => {
          state.views = state.views.filter((view) => {
            return view.uuid !== action.meta.arg;
          });
        },
      },
    ),
  }),
  selectors: {
    selectViews: (state) => {
      return state.views;
    },
    selectIsLoading: (state) => {
      return state.status === DataStatus.loading;
    },
    selectIsFailed: (state) => {
      return state.status === DataStatus.error;
    },
  },
});

export const updateViewSnapshot =
  (uuid: string, snapshot: unknown): AppThunk<Promise<void>> =>
  (dispatch) => {
    return dispatch(
      savedViewsSlice.actions.updateView({ uuid, update: { snapshot } }),
    ).then(() => {});
  };

export const unsetAsDefault =
  (uuid: string, hiddenUpdate?: boolean): AppThunk<Promise<void>> =>
  (dispatch) => {
    return dispatch(
      savedViewsSlice.actions.updateView({
        uuid,
        update: {
          isDefault: false,
        },
        hiddenUpdate,
      }),
    ).then(() => {});
  };

export const setAsDefault =
  (uuid: string): AppThunk<Promise<void>> =>
  (dispatch, getState) => {
    const state = getState();
    const views: SavedViewRecord[] = selectViews(state);
    const existingDefault = views.find((view) => {
      return view.savedView.isDefault;
    });

    return Promise.all([
      existingDefault
        ? dispatch(unsetAsDefault(existingDefault.uuid, true))
        : Promise.resolve(),
      dispatch(
        savedViewsSlice.actions.updateView({
          uuid,
          update: { isDefault: true },
        }),
      ),
    ]).then(() => {});
  };

export const { setupViews, createView, deleteView } = savedViewsSlice.actions;
export const { selectIsFailed, selectIsLoading, selectViews } =
  savedViewsSlice.selectors;
