import InfoIcon from '@mui/icons-material/ErrorOutlineRounded';
import { Box, Divider, Tooltip, Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import { useMemo, useState } from 'react';
import { isFinished } from '@/shared/lib';
import { Button as SharedButton } from '@/shared/ui';
import type { StringFilterParams, StringFilterValue } from '../../model/types';
import {
  FilterSelectionControlsProvider,
  useFilterSelectionControlsContext,
} from '../FilterSelectionControlsContext/FilterSelectionControlsContext';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import { StringFilter } from '../StringFilter/StringFilter';

const Button = styled(SharedButton)(({ theme }) => ({
  padding: `${theme.spacing(0.5)} ${theme.spacing(1.25)}`,
}));

const getSelectedCountLabel = (
  optionsCount: number,
  valueCount: number,
): ReactNode => {
  if (valueCount === 0) {
    return 'Selected none';
  }

  if (valueCount === optionsCount) {
    return 'Selected All';
  }

  return (
    <>
      Selected <b>{valueCount}</b> of {optionsCount}
    </>
  );
};

interface ContentProps {
  id: string;
  value: StringFilterValue;
  onChange: (v: StringFilterValue) => void;
  onApply: () => void;
}

function Content({ id, value, onChange, onApply }: ContentProps) {
  const {
    getSelectAllValue,
    getClearAllValue,
    isClearAllDisabled,
    isSelectAllDisabled,
  } = useFilterSelectionControlsContext();
  const { configsMap, paramsData } = useFiltersParamsContext();
  const filterParamsData = paramsData[id];
  const options = useMemo(() => {
    return (filterParamsData?.params as StringFilterParams)?.options || [];
  }, [filterParamsData]);
  const { name } = configsMap[id] || {};
  const notPresentOptions = useMemo(() => {
    const availableOptions = new Set(options);

    return (value || []).filter((option) => {
      return !availableOptions.has(option);
    });
  }, [value, options]);

  const handleClearAll = () => {
    onChange(getClearAllValue());
  };
  const handleSelectAll = () => {
    onChange(getSelectAllValue());
  };

  return (
    <>
      <Box
        sx={{
          pt: 3,
          px: 3,
          pb: 1.25,
          display: 'flex',
          flexDirection: 'column',
          width: 384,
        }}
      >
        <Box
          sx={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'space-between',
            mb: 2,
            gap: 2,
            color: 'var(--neutral-700)',
          }}
        >
          <Typography
            sx={{
              fontWeight: 500,
              lineHeight: '22px',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {name}
          </Typography>
          <Box sx={{ display: 'flex', alignItems: 'center', gap: 2 }}>
            {isFinished(paramsData[id]?.status) &&
              notPresentOptions.length > 0 && (
                <Tooltip
                  arrow
                  placement="bottom"
                  title={
                    <Typography variant="caption">
                      Some of the selected values are not present in the list.
                    </Typography>
                  }
                >
                  <InfoIcon
                    color="info"
                    sx={{
                      p: 0.5,
                      borderRadius: 1,
                      backgroundColor: '#e5f6fd',
                    }}
                  />
                </Tooltip>
              )}
            <Typography
              sx={{ flexShrink: 0, whiteSpace: 'nowrap', fontWeight: 400 }}
              variant="label"
            >
              {getSelectedCountLabel(
                options.length,
                value.length - notPresentOptions.length,
              )}
            </Typography>
          </Box>
        </Box>
        <StringFilter id={id} value={value} onChange={onChange} />
      </Box>
      <Divider />
      <Box
        sx={{
          py: 2,
          px: 3,
          display: 'flex',
          justifyContent: 'flex-end',
          gap: 2,
        }}
      >
        <Button
          size="small"
          color="secondary"
          variant="text"
          onClick={handleClearAll}
          disabled={isClearAllDisabled(value)}
        >
          Clear All
        </Button>
        <Button
          size="small"
          color="secondary"
          variant="outlined"
          onClick={handleSelectAll}
          disabled={isSelectAllDisabled(value)}
        >
          Select all
        </Button>
        <Button size="small" variant="contained" onClick={onApply}>
          Apply
        </Button>
      </Box>
    </>
  );
}

interface Props {
  id: string;
  value?: StringFilterValue;
  onChange: (v: StringFilterValue) => void;
}

export function QuickStringFilter({ id, value, onChange }: Props) {
  const [internalValue, setInternalValue] = useState<StringFilterValue>(
    value || [],
  );

  const handleChange = (newValue: StringFilterValue) => {
    setInternalValue(newValue);
  };
  const handleApply = () => {
    onChange(internalValue);
  };

  return (
    <FilterSelectionControlsProvider
      id={id}
      value={internalValue}
      getOptionValue={(option) => option as string}
    >
      <Content
        id={id}
        value={internalValue}
        onApply={handleApply}
        onChange={handleChange}
      />
    </FilterSelectionControlsProvider>
  );
}
