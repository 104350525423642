import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { parseMediaColumnValue } from '@/shared/data';
import { DataType } from '@/shared/lib';
import { DataStatus, isFinished, isLoading } from '@/shared/lib/dataFetching';
import {
  isMediaFilter,
  isNumberFilter,
  isStringFilter,
} from '../../model/predicates';
import type {
  ColumnsConfig,
  FilterConfig,
  FilterParams,
  Filters,
  FiltersParamsData,
  MediaFilterParams,
  NumberFilterParams,
  StringFilterParams,
  FilterType,
} from '../../model/types';
import { StringFilterType } from '../../model/types';
import { useFilterConfigs } from './useFilterConfigs';

interface ContextState {
  paramsData: FiltersParamsData;
  configs: FilterConfig[];
  configsMap: Record<string, FilterConfig>;
  requestStringFilterParams: (id: string) => void;
  requestNumberFilterParams: (id: string) => void;
  requestMediaFilterParams: (id: string) => void;
  resetParamsData: (filters: Filters) => void;
}

const Context = createContext<ContextState | null>(null);

interface Props {
  children: ReactNode;
  columnsConfig: ColumnsConfig;
  allDimensions: string[];
  allMetrics: string[];
  numberFilterParamsDeps: unknown[];
  stringFilterParamsDeps: unknown[];
  getNumberFilterParams: (id: string) => Promise<NumberFilterParams>;
  getStringFilterParams: (id: string) => Promise<StringFilterParams>;
}

export function FiltersParamsProvider({
  children,
  columnsConfig,
  allDimensions,
  allMetrics,
  numberFilterParamsDeps,
  stringFilterParamsDeps,
  getNumberFilterParams,
  getStringFilterParams,
}: Props) {
  const configs = useFilterConfigs({
    columnsConfig,
    allDimensions,
    allMetrics,
  });
  const [paramsData, setParamsData] = useState<FiltersParamsData>({});
  const configsMap = useMemo(() => {
    return Object.fromEntries(
      configs.map((config) => {
        return [config.id, config];
      }),
    );
  }, [configs]);
  const requestParams = useCallback(
    (id: string, type: FilterType, request: Promise<FilterParams>) => {
      if (
        paramsData[id]?.status &&
        (isLoading(paramsData[id]?.status) ||
          isFinished(paramsData[id]?.status))
      ) {
        return;
      }

      setParamsData((oldFiltersParamsData) => {
        return {
          ...oldFiltersParamsData,
          [id]: {
            status: DataStatus.loading,
          },
        };
      });

      request
        .then((params) => {
          setParamsData((oldFiltersParamsData) => {
            return {
              ...oldFiltersParamsData,
              [id]: {
                status: DataStatus.finished,
                type,
                params,
              },
            };
          });
        })
        .catch((error) => {
          setParamsData((oldFiltersParamsData) => {
            return {
              ...oldFiltersParamsData,
              [id]: {
                status: DataStatus.error,
                error: (error as Error).message,
              },
            };
          });
        });
    },
    [paramsData],
  );

  useEffect(() => {
    setParamsData((oldFiltersParamsData) => {
      return Object.fromEntries(
        Object.entries(oldFiltersParamsData).filter(([_key, value]) => {
          return !value.type || !isNumberFilter(value.type);
        }),
      );
    });
  }, numberFilterParamsDeps);
  useEffect(() => {
    setParamsData((oldFiltersParamsData) => {
      return Object.fromEntries(
        Object.entries(oldFiltersParamsData).filter(([_key, value]) => {
          return (
            !value.type ||
            (!isStringFilter(value.type) && !isMediaFilter(value.type))
          );
        }),
      );
    });
  }, stringFilterParamsDeps);

  return (
    <Context.Provider
      value={{
        configs,
        configsMap,
        paramsData,
        requestMediaFilterParams(id) {
          requestParams(
            id,
            StringFilterType.MEDIA,
            getStringFilterParams(id).then((params): MediaFilterParams => {
              return {
                options: params.options.map((option) => {
                  return parseMediaColumnValue(option);
                }),
              };
            }),
          );
        },
        requestNumberFilterParams(id) {
          requestParams(id, DataType.NUMBER, getNumberFilterParams(id));
        },
        requestStringFilterParams(id) {
          requestParams(id, StringFilterType.TEXT, getStringFilterParams(id));
        },
        resetParamsData(filters: Filters) {
          setParamsData((oldParamsData) => {
            const USED_FILTERS = new Set(
              filters.map((filter) => {
                return filter.id;
              }),
            );

            return Object.fromEntries(
              Object.entries(oldParamsData).filter(([key]) => {
                return USED_FILTERS.has(key);
              }),
            );
          });
        },
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const useFiltersParamsContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useFiltersParamsCotext hook used outside <FiltersParamsProvider />',
    );
  }

  return context;
};
