import type { ReactNode } from 'react';
import { useState } from 'react';
import { Analytics, isLoading } from '@/shared/lib';
import { Dropdown } from '@/shared/ui';
import {
  isMediaFilter,
  isNumberFilter,
  isStringFilter,
} from '../../model/predicates';
import type {
  FilterValue,
  MediaFilterValue,
  NumberFilterValue,
  StringFilterValue,
} from '../../model/types';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import { useQuickFiltersContext } from '../QuickFiltersContext/QuickFiltersContext';
import { QuickMediaFilter } from '../QuickMediaFilter/QuickMediaFilter';
import { QuickNumberFilter } from '../QuickNumberFilter/QuickNumberFilter';
import { QuickStringFilter } from '../QuickStringFilter/QuickStringFilter';

interface Props {
  id: string;
}

export function QuickFilter({ id }: Props) {
  const { paramsData, configsMap } = useFiltersParamsContext();
  const { filtersMap, updateFilter } = useQuickFiltersContext();
  const [open, setOpen] = useState(false);
  const { type, name } = configsMap[id] || {};
  const active = !!filtersMap[id];
  const loading = !paramsData[id] || isLoading(paramsData[id].status);

  const handleFilterChange = (value: FilterValue) => {
    updateFilter(id, value);
    setOpen(false);
    Analytics.sendFeatureUsage('quick_filter', 'change_value', {
      filterName: id,
    });
  };

  let label: string = '';
  let content: ReactNode;

  if (isStringFilter(type)) {
    const value = filtersMap[id] as StringFilterValue;
    content = (
      <QuickStringFilter id={id} value={value} onChange={handleFilterChange} />
    );
    label = getLabelWithCount(name, value?.length);
  } else if (isNumberFilter(type)) {
    content = (
      <QuickNumberFilter
        id={id}
        value={filtersMap[id] as NumberFilterValue}
        onChange={handleFilterChange}
      />
    );
    label = name;
  } else if (isMediaFilter(type)) {
    const value = filtersMap[id] as MediaFilterValue;
    content = (
      <QuickMediaFilter id={id} value={value} onChange={handleFilterChange} />
    );
    label = getLabelWithCount(name, value?.length);
  }

  return (
    <Dropdown
      labelProps={{
        sx: {
          flexShrink: 0,
        },
      }}
      open={open}
      active={active}
      loading={loading}
      label={label}
      onOpenChange={setOpen}
    >
      {content}
    </Dropdown>
  );
}

const getLabelWithCount = (name: string, valueCount?: number): string => {
  const postfix = valueCount != null ? ` (${valueCount})` : '';

  return `${name}${postfix}`;
};
