import {
  Box,
  Button,
  CircularProgress,
  InputLabel as MUIInputLabel,
  OutlinedInput,
  Typography,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ChangeEventHandler, FormEventHandler } from 'react';
import { useState } from 'react';
import { Analytics } from '@/shared/lib';
import { useSavedViewsContext } from '../SavedViewsContext/SavedViewsContext';

const Container = styled('form')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  gap: theme.spacing(2),
}));
const InputLabel = styled(MUIInputLabel)(({ theme }) => ({
  fontSize: '12px',
  fontWeight: 500,
  lineHeight: '20px',
  marginBottom: theme.spacing(0.5),
  color: theme.palette.text.primary,

  '&::before': {
    content: '"*"',
    color: theme.palette.error.main,
  },
}));

export function SaveViewForm() {
  const { createView } = useSavedViewsContext();
  const [savingInProgress, setSavingInProgress] = useState(false);
  const [name, setName] = useState('');
  const isNameEmpty = name.trim() === '';

  const handleChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    setName(event.target.value);
  };
  const handleSubmit: FormEventHandler<HTMLFormElement> = (event) => {
    event.preventDefault();

    if (!name) {
      return;
    }

    setSavingInProgress(true);
    createView(name)
      .then(() => {
        setName('');
        Analytics.sendFeatureUsage('saved_views', 'create_view');
      })
      .finally(() => {
        setSavingInProgress(false);
      });
  };

  return (
    <Container onSubmit={handleSubmit}>
      <Typography
        component="h6"
        sx={{
          fontSize: '0.875rem',
          lineHeight: 1.43,
          fontWeight: 500,
          textTransform: 'uppercase',
        }}
      >
        Save custom view
      </Typography>
      <Typography sx={{ fontSize: '14px', color: 'text.secondary' }}>
        Save your own custom view with all filters applied, to eliminate the
        need to filter every time you open the dashboard.
      </Typography>
      <Typography sx={{ fontSize: '14px', color: 'text.secondary' }}>
        Also you can assign view as "Default" and it will be opened
        automatically when you open the view.
      </Typography>
      <div>
        <InputLabel htmlFor="viewName"> Name this view</InputLabel>
        <OutlinedInput
          id="viewName"
          name="name"
          size="small"
          fullWidth
          placeholder="Enter view name"
          disabled={savingInProgress}
          value={name}
          onChange={handleChange}
        />
      </div>
      <Box sx={{ display: 'flex', justifyContent: 'flex-end' }}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={isNameEmpty || savingInProgress}
        >
          Save
          {savingInProgress && (
            <CircularProgress
              color="inherit"
              size={24}
              sx={{ position: 'absolute' }}
            />
          )}
        </Button>
      </Box>
    </Container>
  );
}
