import type { GridApiPro } from '@mui/x-data-grid-pro';
import type { ReactNode } from 'react';
import { createContext, useContext, useState } from 'react';

interface ContextState {
  apiRef: GridApiPro | null;
  setApiRef: (v: GridApiPro) => void;
}

const Context = createContext<ContextState | null>(null);

interface Props {
  children: ReactNode;
}

export function DataGridApiRefProvider({ children }: Props) {
  const [apiRef, setApiRef] = useState<GridApiPro | null>(null);

  return (
    <Context.Provider value={{ apiRef, setApiRef }}>
      {children}
    </Context.Provider>
  );
}

export const useDataGridApiRefContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useDataGridApiRefContext hook used outside <DataGridApiRefProvider />',
    );
  }

  return context;
};
