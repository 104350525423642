import { Typography } from '@mui/material';
import { useEffect, useMemo, useRef, useState } from 'react';
import { isFinished } from '@/shared/lib/dataFetching';
import { SortOptions } from '@/shared/types';
import { SortButton } from '@/shared/ui';
import type { StringFilterParams, StringFilterValue } from '../../model/types';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import type { ItemRendererProps } from '../VirtualizedFilter/VirtualizedFilter';
import { VirtualizedFilter } from '../VirtualizedFilter/VirtualizedFilter';

interface Props {
  id: string;
  value?: StringFilterValue;
  onChange: (v: StringFilterValue) => void;
}

export function StringFilter({ id, value, onChange }: Props) {
  const { paramsData, requestStringFilterParams } = useFiltersParamsContext();
  const [sortOrder, setSortOrder] = useState(SortOptions.ASC);
  const optionsSortComparator = useMemo(() => {
    return sortOrder === SortOptions.ASC
      ? (a: string, b: string) => a.localeCompare(b)
      : (a: string, b: string) => b.localeCompare(a);
  }, [sortOrder]);
  const allOptions = (paramsData[id]?.params as StringFilterParams)?.options;

  useEffect(() => {
    requestStringFilterParams(id);
  }, []);

  useEffect(() => {
    if (isFinished(paramsData[id]?.status) && (!value || value.length === 0)) {
      onChange(allOptions);
    }
  }, [paramsData[id]]);

  const handleSortOrderChange = () => {
    setSortOrder(
      sortOrder === SortOptions.DESC ? SortOptions.ASC : SortOptions.DESC,
    );
  };

  return (
    <VirtualizedFilter
      filtersSlot={
        <Typography
          variant="modalContent"
          sx={{ display: 'flex', alignItems: 'center', gap: 1, mb: 2 }}
        >
          <span>Sort</span>
          <SortButton value={sortOrder} onChange={handleSortOrderChange} />
        </Typography>
      }
      ItemRenderer={StringOptionRenderer}
      value={value}
      onChange={onChange}
      optionsSortComparator={optionsSortComparator}
    />
  );
}

function StringOptionRenderer({ value, setHeight }: ItemRendererProps) {
  const rootRef = useRef<HTMLDivElement>(null);

  useEffect(
    () => {
      if (rootRef.current) {
        setHeight(rootRef.current.clientHeight);
      }
    }, // eslint-disable-next-line react-hooks/exhaustive-deps
    [rootRef],
  );

  return <div ref={rootRef}>{value as string}</div>;
}
