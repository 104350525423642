import { IconButton as MUIIconButton, Tooltip } from '@mui/material';
import { styled } from '@mui/material/styles';
import type { ReactNode } from 'react';
import { TrashIcon } from '@/shared/ui';

const LabelWrapper = styled('div')(() => ({
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  whiteSpace: 'nowrap',
  fontWeight: 500,
}));
const IconButton = styled(MUIIconButton)(({ theme }) => ({
  borderRadius: theme.spacing(0.5),
  padding: 0,
}));

interface Props {
  title: string;
  description?: string;
  sortButton: ReactNode;
  onDelete: () => void;
}

export function HeaderCell({
  title,
  description,
  sortButton,
  onDelete,
}: Props) {
  return (
    <>
      <Tooltip
        arrow
        title={
          <>
            <p>{title}</p>
            {description && <p>{description}</p>}
          </>
        }
      >
        <LabelWrapper>{title}</LabelWrapper>
      </Tooltip>
      <IconButton sx={{ mx: 1 }} onClick={onDelete}>
        <TrashIcon />
      </IconButton>
      {sortButton}
    </>
  );
}
