export interface MediaColumnValue {
  value: string;
  type: 'image';
  url: string;
}

export const parseMediaColumnValue = (
  columnValue: string,
): MediaColumnValue => {
  try {
    return JSON.parse(columnValue);
  } catch (_error) {
    return {
      value: '',
      type: 'image',
      url: '',
    };
  }
};
