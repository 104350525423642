import { Typography } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useState } from 'react';
import { useRouteError } from 'react-router-dom';
import { Header } from '@/widgets/Header';
import { ProfileMenu } from '@/widgets/ProfileMenu';
import { Monitoring } from '@/shared/lib';
import { Button, Layout } from '@/shared/ui';

const ErrorContainer = styled('div')(({ theme }) => ({
  display: 'flex',
  flexDirection: 'column',
  alignItems: 'center',
  justifyContent: 'center',
  gap: theme.spacing(2),
  textAlign: 'center',
  padding: theme.spacing(2),
  animation: 'fadeIn 0.5s ease-out',
  margin: '0 auto',

  '@keyframes fadeIn': {
    from: { opacity: 0, transform: 'translateY(20px)' },
    to: { opacity: 1, transform: 'translateY(0)' },
  },

  '@keyframes fallAndRotate': {
    '0%': { transform: 'translateY(0) rotate(0deg)', opacity: 1 },
    '100%': { transform: 'translateY(100px) rotate(20deg)', opacity: 0 },
  },

  '& .error-svg': {
    width: 300,
    height: 300,
  },
}));

export function GlobalErrorBoundary() {
  const [errorId, setErrorId] = useState('');
  const error = useRouteError();

  useEffect(() => {
    setErrorId(Monitoring.captureError(error) || '');
  }, [error]);

  const handleReload = () => {
    window.location.reload();
  };

  return (
    <Layout
      headerSlot={<Header rightContentSlot={<ProfileMenu />} />}
      slotProps={{
        content: {
          sx: {
            display: 'flex',
          },
        },
      }}
    >
      <ErrorContainer>
        <svg
          className="error-svg"
          viewBox="0 0 240 240"
          xmlns="http://www.w3.org/2000/svg"
        >
          {/* Background Grid (Stable) */}
          <g fill="none" stroke="#e5e7eb" strokeWidth="1">
            <rect
              x="20"
              y="20"
              width="200"
              height="200"
              stroke="#d1d5db"
              strokeWidth="2"
            />
            {/*  Vertical lines */}
            <line x1="60" y1="20" x2="60" y2="220" />
            <line x1="100" y1="20" x2="100" y2="220" />
            <line x1="140" y1="20" x2="140" y2="220" />
            <line x1="180" y1="20" x2="180" y2="220" />
            {/*  Horizontal lines */}
            <line x1="20" y1="60" x2="220" y2="60" />
            <line x1="20" y1="100" x2="220" y2="100" />
            <line x1="20" y1="140" x2="220" y2="140" />
            <line x1="20" y1="180" x2="220" y2="180" />
          </g>

          {/* Falling Pieces */}
          <g>
            <g style={{ animation: 'fallAndRotate 2s ease-in infinite' }}>
              <rect
                x="60"
                y="60"
                width="40"
                height="40"
                fill="#f3f4f6"
                stroke="#d1d5db"
              />
              <text
                x="70"
                y="85"
                fontFamily="monospace"
                fontSize="10"
                fill="#6b7280"
              >
                A1
              </text>
            </g>
            <g style={{ animation: 'fallAndRotate 2.2s ease-in infinite' }}>
              <rect
                x="100"
                y="100"
                width="40"
                height="40"
                fill="#f3f4f6"
                stroke="#d1d5db"
              />
              <text
                x="110"
                y="125"
                fontFamily="monospace"
                fontSize="10"
                fill="#6b7280"
              >
                B2
              </text>
            </g>
            <g
              style={{
                animation: 'fallAndRotate 1.8s ease-in infinite',
              }}
            >
              <rect
                x="140"
                y="140"
                width="40"
                height="40"
                fill="#f3f4f6"
                stroke="#d1d5db"
              />
              <text
                x="150"
                y="165"
                fontFamily="monospace"
                fontSize="10"
                fill="#6b7280"
              >
                C3
              </text>
            </g>
            <g style={{ animation: 'fallAndRotate 2.4s ease-in infinite' }}>
              <rect
                x="60"
                y="140"
                width="40"
                height="40"
                fill="#f3f4f6"
                stroke="#d1d5db"
              />
              <text
                x="70"
                y="165"
                fontFamily="monospace"
                fontSize="10"
                fill="#6b7280"
              >
                D4
              </text>
            </g>
          </g>

          {/* Error Symbols */}
          <g fill="#ef4444">
            <text x="40" y="40" fontFamily="monospace" fontSize="14">
              !
            </text>
            <text x="180" y="40" fontFamily="monospace" fontSize="14">
              ?
            </text>
            <text x="40" y="200" fontFamily="monospace" fontSize="14">
              !
            </text>
            <text x="180" y="200" fontFamily="monospace" fontSize="14">
              ?
            </text>
          </g>
        </svg>
        <Typography variant="h4" fontWeight={500}>
          Oops! Something went wrong
        </Typography>
        <Typography variant="body1">
          We track these errors automatically, but if the problem persists feel
          free to contact us. Please try again.
        </Typography>
        {errorId && (
          <Typography variant="caption" sx={{ color: '#ccc' }}>
            Log ID: {errorId}
          </Typography>
        )}
        <Button onClick={handleReload}>Reload the page</Button>
      </ErrorContainer>
    </Layout>
  );
}
