import { Box } from '@mui/material';
import { styled } from '@mui/material/styles';
import { useEffect, useRef } from 'react';
import { isFinished } from '@/shared/lib/dataFetching';
import type {
  MediaFilterOption,
  MediaFilterParams,
  MediaFilterValue,
} from '../../model/types';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';
import type { ItemRendererProps } from '../VirtualizedFilter/VirtualizedFilter';
import { VirtualizedFilter } from '../VirtualizedFilter/VirtualizedFilter';

const Image = styled('img')(() => ({
  height: 22,
  aspectRatio: '1 / 1',
  objectFit: 'contain',
}));

interface Props {
  id: string;
  value?: MediaFilterValue;
  onChange: (v: MediaFilterValue) => void;
}

export function MediaFilter({ id, value, onChange }: Props) {
  const { paramsData, requestMediaFilterParams } = useFiltersParamsContext();

  useEffect(() => {
    requestMediaFilterParams(id);
  }, []);

  useEffect(() => {
    if (isFinished(paramsData[id]?.status) && (!value || value.length === 0)) {
      onChange(
        (paramsData[id].params as MediaFilterParams).options.map((option) => {
          return option.value;
        }),
      );
    }
  }, [paramsData[id]]);

  return (
    <VirtualizedFilter
      ItemRenderer={MediaOptionRenderer}
      value={value}
      onChange={onChange}
    />
  );
}

function MediaOptionRenderer({ value, setHeight }: ItemRendererProps) {
  const rootRef = useRef<HTMLDivElement>(null);
  const { url, value: optionValue } = value as MediaFilterOption;

  useEffect(
    () => {
      if (rootRef.current) {
        setHeight(rootRef.current.clientHeight);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [rootRef],
  );

  return (
    <Box ref={rootRef} sx={{ display: 'flex', alignItems: 'center', gap: 1 }}>
      {url && <Image src={url} />}{' '}
      <Box component="span" sx={{ wordBreak: 'break-word' }}>
        {optionValue}
      </Box>
    </Box>
  );
}
