import { format, isValid } from 'date-fns';

export function formatDate(value: string) {
  const dateValue = new Date(value);

  if (!isValid(dateValue)) {
    return value;
  }

  return dateValue.toLocaleDateString();
}

export function formatTime(value: string) {
  const dateValue = new Date(value);

  if (!isValid(dateValue)) {
    return value;
  }

  return dateValue.toLocaleTimeString();
}

export function formatTimezone(value: string) {
  const dateValue = new Date(value);

  if (!isValid(dateValue)) {
    return value;
  }

  return format(dateValue, 'zzzz');
}

export function convertUTCtoISO(value: string) {
  const [date, time] = value.split(' ');

  return `${date}T${time}Z`;
}
