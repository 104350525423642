import { z } from 'zod';
import { metricColoringValidator } from '@/features/DataGridColoring';
import { DataGridViewType } from '@/features/DataGridTypeSwitcher';
import type { Filters } from '@/features/filters';
import { filtersValidator } from '@/features/filters';
import type { SerializedDateRangeValueObject, Sorting } from '@/shared/types';
import {
  DateScale,
  Lifetime,
  columnsWidthStateValidator,
  serializedDateRangeValueObjectValidator,
  sortingValidator,
} from '@/shared/types';

export interface MarketingPerformanceDataPage {
  rows: Record<string, string | number>[];
  hasNextPage: boolean;
}

export const dataConfigValidator = z.object({
  dimensions: z.array(z.string()),
  metrics: z.array(z.string()),
  dateScale: z.nativeEnum(DateScale),
  dateRange: serializedDateRangeValueObjectValidator,
  sorting: sortingValidator,
  lifetime: z.nativeEnum(Lifetime),
  probabilisticAttribution: z.boolean(),
  filters: filtersValidator,
  rowHeight: z.number(),
  columnsWidth: columnsWidthStateValidator,
  metricsColoring: z.array(metricColoringValidator).optional(),
  dataGridViewType: z.optional(z.nativeEnum(DataGridViewType)),
});

export type DataConfig = z.infer<typeof dataConfigValidator>;

export interface GetDataParams {
  dimensions: string[];
  dateScale: DateScale;
  dateRange: SerializedDateRangeValueObject;
  sorting: Sorting;
  probabilisticAttribution: boolean;
  lifetime: Lifetime;
  filters: Filters;
  offset?: number;
}
export interface GetMetricsMinMaxParams {
  dimensions: string[];
  metrics: string[];
  filters: Filters;
  dateRange: SerializedDateRangeValueObject;
  dateScale: DateScale;
  probabilisticAttribution: boolean;
  lifetime: number;
}

export interface GetTotalParams {
  dimensions: string[];
  metrics: string[];
  filters: Filters;
  dateRange: SerializedDateRangeValueObject;
  probabilisticAttribution: boolean;
  lifetime: number;
}

export interface DataUpdateInfo {
  updateTime: string;
}
