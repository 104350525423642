import { createSelector } from '@reduxjs/toolkit';
import type { MetricColoring } from '@/features/DataGridColoring';
import { DataGridViewType } from '@/features/DataGridTypeSwitcher';
import type { DownloadData } from '@/features/DownloadData';
import type {
  Filters,
  NumberFilterParams,
  StringFilterParams,
} from '@/features/filters';
import { getApplicableFilters, prepareFilters } from '@/features/filters';
import type { ColumnsMeta } from '@/entities/columnsConfig';
import { prepareDateRangeValueObject } from '@/shared/api';
import { DEFAULT_PAGE_SIZE, RowHeights } from '@/shared/constants';
import { DataStatus } from '@/shared/lib/dataFetching';
import type { AppThunk } from '@/shared/model';
import { createAppSlice } from '@/shared/model';
import type {
  SerializedDateRangeValueObject,
  Sorting,
  SortingItem,
} from '@/shared/types';
import { DateRangePresets, DateScale } from '@/shared/types';
import type { MinMaxData, TotalData } from '@/shared/ui';
import * as CalendarInsightsAPI from '../api/calendarInsightsAPI';
import type { ParentGroup, TreeDataRow } from '../api/types';
import type {
  DataConfig,
  GetDataParams,
  GetMetricsMinMaxParams,
  GetTotalParams,
  CalendarInsightsDataPage,
} from './types';

export interface State {
  meta: {
    data: ColumnsMeta;
    status: DataStatus;
  };
  config: DataConfig;
}

const initialState: State = {
  meta: {
    status: DataStatus.idle,
    data: {
      defaultColumns: { dimensions: [], metrics: [] },
      config: {},
      dimensions: [],
      metrics: [],
    },
  },
  config: {
    dimensions: [],
    metrics: [],
    cohortDateScale: DateScale.DAY,
    cohortDateRange: { preset: DateRangePresets.ALL_TIME },
    revenueDateScale: DateScale.DAY,
    revenueDateRange: { preset: DateRangePresets.LAST_30_DAYS },
    sorting: [],
    probabilisticAttribution: true,
    filters: [],
    rowHeight: RowHeights.normal,
    columnsWidth: {},
    metricsColoring: [],
    dataGridViewType: DataGridViewType.dataTable,
  },
};

export const calendarInsightsSlice = createAppSlice({
  name: 'calendarInsights',
  initialState,
  reducers: (create) => ({
    reset: create.reducer(() => {
      return { ...initialState };
    }),
    setupColumnsMeta: create.asyncThunk(
      (_: void) => {
        return CalendarInsightsAPI.getColumnsMeta();
      },
      {
        pending: (state) => {
          state.meta.status = DataStatus.loading;
        },
        fulfilled: (state, action) => {
          state.meta.data = action.payload;
          state.meta.status = DataStatus.finished;
        },
        rejected: (state) => {
          state.meta.status = DataStatus.error;
        },
      },
    ),
    setDefaultColumns: create.reducer((state) => {
      state.config.dimensions = state.meta.data.defaultColumns.dimensions;
      state.config.metrics = state.meta.data.defaultColumns.metrics;
      state.config.sorting = [];
      state.config.metricsColoring = [];
    }),
    updateConfigRowHeight: create.reducer<number>((state, action) => {
      state.config.rowHeight = action.payload;
    }),
    updateColumns: create.reducer<{ dimensions: string[]; metrics: string[] }>(
      (state, action) => {
        const allowedColumns = new Set([
          ...action.payload.dimensions,
          ...action.payload.metrics,
        ]);
        const filteredSorting = state.config.sorting.filter((sortItem) => {
          return allowedColumns.has(sortItem.name);
        });
        const filteredMetricsColoring = (
          state.config.metricsColoring || []
        ).filter((metricColoring) => {
          return allowedColumns.has(metricColoring.metric);
        });

        if (state.config.sorting.length !== filteredSorting.length) {
          state.config.sorting = filteredSorting;
        }

        if (
          state.config.metricsColoring?.length !==
          filteredMetricsColoring.length
        ) {
          state.config.metricsColoring = filteredMetricsColoring;
        }

        state.config.dimensions = action.payload.dimensions;
        state.config.metrics = action.payload.metrics;
      },
    ),
    updateCohortDateScale: create.reducer<DateScale>((state, action) => {
      state.config.cohortDateScale = action.payload;
    }),
    updateCohortDateRange: create.reducer<SerializedDateRangeValueObject>(
      (state, action) => {
        state.config.cohortDateRange = action.payload;
      },
    ),
    updateRevenueDateScale: create.reducer<DateScale>((state, action) => {
      state.config.revenueDateScale = action.payload;
    }),
    updateRevenueDateRange: create.reducer<SerializedDateRangeValueObject>(
      (state, action) => {
        state.config.revenueDateRange = action.payload;
      },
    ),
    updateProbabilisticAttribution: create.reducer<boolean>((state, action) => {
      state.config.probabilisticAttribution = action.payload;
    }),
    updateDataGridViewType: create.reducer<DataGridViewType>(
      (state, action) => {
        state.config.dataGridViewType = action.payload;
      },
    ),
    reorderDimensions: create.reducer<{
      id: string;
      oldIndex: number;
      newIndex: number;
    }>((state, action) => {
      const newDimensions = state.config.dimensions.toSpliced(
        action.payload.oldIndex,
        1,
      );

      newDimensions.splice(action.payload.newIndex, 0, action.payload.id);
      state.config.dimensions = newDimensions;
    }),
    reorderMetrics: create.reducer<{
      id: string;
      oldIndex: number;
      newIndex: number;
    }>((state, action) => {
      const newMetrics = state.config.metrics.toSpliced(
        action.payload.oldIndex,
        1,
      );

      newMetrics.splice(action.payload.newIndex, 0, action.payload.id);
      state.config.metrics = newMetrics;
    }),
    updateConfigMetricsColoring: create.reducer<MetricColoring[]>(
      (state, action) => {
        state.config.metricsColoring = action.payload;
      },
    ),
    updateSorting: create.reducer<{ column: string; item?: SortingItem }>(
      (state, action) => {
        if (!action.payload.item) {
          state.config.sorting = state.config.sorting.filter((item) => {
            return item.name !== action.payload.column;
          });
        } else {
          const index = state.config.sorting.findIndex((item) => {
            return item.name === action.payload.column;
          });

          if (index !== -1) {
            state.config.sorting[index] = action.payload.item;
          } else {
            state.config.sorting.push(action.payload.item);
          }
        }
      },
    ),
    updateFilters: create.reducer<Filters>((state, action) => {
      state.config.filters = action.payload;
    }),
    resetFilters: create.reducer((state) => {
      state.config.filters = [];
    }),
    resetConfig: create.reducer((state) => {
      state.config = { ...initialState.config };
    }),
    updateConfig: create.reducer<DataConfig>((state, action) => {
      state.config = action.payload;
    }),
    updateConfigColumnsWidth: create.reducer<{ name: string; width?: number }>(
      (state, action) => {
        state.config.columnsWidth = {
          ...state.config.columnsWidth,
          [action.payload.name]: action.payload.width,
        };
      },
    ),
  }),
  selectors: {
    selectAdvaceSortableOptions: createSelector(
      (sliceState: State) => sliceState.meta.data.config,
      (sliceState: State) => sliceState.config.metrics,
      (config, metrics) => {
        return metrics
          .filter((metric) => {
            return config[metric].isAdvancedSortable;
          })
          .map((metric) => {
            return {
              name: config[metric].name,
              value: metric,
            };
          });
      },
    ),
    selectMetaIsFailed: (state) => {
      return state.meta.status === DataStatus.error;
    },
    selectMetaIsLoaded: (state) => {
      return state.meta.status === DataStatus.finished;
    },
    selectMetaIsLoading: (state) => {
      return state.meta.status === DataStatus.loading;
    },
    selectMetaAllDimensions: (state) => {
      return state.meta.data.dimensions;
    },
    selectMetaAllMetrics: (state) => {
      return state.meta.data.metrics;
    },
    selectMetaConfig: (state) => {
      return state.meta.data.config;
    },
    selectConfigMetrics: (state) => {
      return state.config.metrics;
    },
    selectConfigDimensions: (state) => {
      return state.config.dimensions;
    },
    selectConfigSorting: (state) => {
      return state.config.sorting;
    },
    selectConfigRowHeight: (state) => {
      return state.config.rowHeight;
    },
    selectCohortDateScale: (state) => {
      return state.config.cohortDateScale;
    },
    selectCohortDateRange: (state) => {
      return state.config.cohortDateRange;
    },
    selectRevenueDateScale: (state) => {
      return state.config.revenueDateScale;
    },
    selectRevenueDateRange: (state) => {
      return state.config.revenueDateRange;
    },
    selectProbabilisticAttribution: (state) => {
      return state.config.probabilisticAttribution;
    },
    selectConfigDataGridViewType: (state) => {
      return state.config.dataGridViewType;
    },
    selectIsTreeDataGridView: (state) => {
      return state.config.dataGridViewType === DataGridViewType.treeDataGrid;
    },
    selectConfig: (state) => {
      return state.config;
    },
    selectFilters: (state) => {
      return state.config.filters;
    },
    selectConfigColumnsWidth: (state) => {
      return state.config.columnsWidth;
    },
    selectConfigMetricsColoring: (state) => {
      return state.config.metricsColoring || [];
    },
  },
});

export const deleteConfigDimension =
  (dimension: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const config = selectConfig(state);

    dispatch(
      updateColumns({
        dimensions: config.dimensions.filter((item) => {
          return item !== dimension;
        }),
        metrics: config.metrics,
      }),
    );
  };

export const deleteConfigMetric =
  (metric: string): AppThunk =>
  (dispatch, getState) => {
    const state = getState();
    const config = selectConfig(state);

    dispatch(
      updateColumns({
        dimensions: config.dimensions,
        metrics: config.metrics.filter((item) => {
          return item !== metric;
        }),
      }),
    );
  };

export const setupColumnsMeta = (): AppThunk => (dispatch) => {
  dispatch(calendarInsightsSlice.actions.setupColumnsMeta()).then(() => {
    dispatch(calendarInsightsSlice.actions.setDefaultColumns());
  });
};

export const resetConfig = (): AppThunk => (dispatch) => {
  dispatch(calendarInsightsSlice.actions.resetConfig());
  dispatch(calendarInsightsSlice.actions.setDefaultColumns());
};

interface DownloadDataParams {
  metrics: string[];
  dimensions: string[];
  cohortDateScale: DateScale;
  cohortDateRange: SerializedDateRangeValueObject;
  revenueDateScale: DateScale;
  revenueDateRange: SerializedDateRangeValueObject;
  sorting: Sorting;
  probabilisticAttribution: boolean;
  filters: Filters;
  allMetrics: string[];
  allDimensions: string[];
}

export const downloadData =
  (params: DownloadDataParams): AppThunk<Promise<DownloadData>> =>
  async (_dispatch) => {
    const DOWNLOAD_PAGE_SIZE = 1000000000;
    const { dimensionsFilters, metricsFilters } = prepareFilters(
      params.filters,
      params.allMetrics,
      params.allDimensions,
    );

    return Promise.all([
      CalendarInsightsAPI.getTotal({
        dimensions: params.dimensions,
        metrics: params.metrics,
        cohortDateRange: prepareDateRangeValueObject(params.cohortDateRange),
        revenueDateRange: prepareDateRangeValueObject(params.revenueDateRange),
        probabilisticAttribution: params.probabilisticAttribution,
        dimensionsFilters,
      }),
      CalendarInsightsAPI.getData({
        dimensions: params.dimensions,
        metrics: params.metrics,
        cohortDateScale: params.cohortDateScale,
        cohortDateRange: prepareDateRangeValueObject(params.cohortDateRange),
        revenueDateScale: params.revenueDateScale,
        revenueDateRange: prepareDateRangeValueObject(params.revenueDateRange),
        offset: 0,
        sort: params.sorting,
        probabilisticAttribution: params.probabilisticAttribution,
        dimensionsFilters,
        metricsFilters,
        pageSize: DOWNLOAD_PAGE_SIZE,
      }),
    ]).then(([totalResponse, rowsResponse]) => {
      return {
        rows: rowsResponse.rows,
        total: totalResponse,
      };
    });
  };

export const getPage =
  (params: GetDataParams): AppThunk<Promise<CalendarInsightsDataPage>> =>
  (_dispatch, getState) => {
    const state = getState();
    // moved here to prevent new fetcher generation on metric column delete
    const metrics = selectConfigMetrics(state);
    const allDimensions = selectMetaAllDimensions(state);
    const allMetrics = selectMetaAllMetrics(state);
    const { dimensionsFilters, metricsFilters } = prepareFilters(
      params.filters,
      allMetrics,
      allDimensions,
    );

    return CalendarInsightsAPI.getData({
      dimensions: params.dimensions,
      metrics,
      cohortDateScale: params.cohortDateScale,
      cohortDateRange: prepareDateRangeValueObject(params.cohortDateRange),
      revenueDateScale: params.revenueDateScale,
      revenueDateRange: prepareDateRangeValueObject(params.revenueDateRange),
      dimensionsFilters,
      metricsFilters,
      probabilisticAttribution: params.probabilisticAttribution,
      sort: params.sorting,
      offset: params.offset || 0,
      pageSize: DEFAULT_PAGE_SIZE,
    });
  };

export const getTotalData =
  (params: GetTotalParams): AppThunk<Promise<TotalData>> =>
  (_dispatch, getState) => {
    if (params.dimensions.length === 0 && params.metrics.length === 0) {
      return Promise.resolve({});
    }

    const state = getState();
    const allDimensions = selectMetaAllDimensions(state);
    const allMetrics = selectMetaAllMetrics(state);
    const { dimensionsFilters } = prepareFilters(
      params.filters,
      allMetrics,
      allDimensions,
    );

    return CalendarInsightsAPI.getTotal({
      dimensions: params.dimensions,
      metrics: params.metrics,
      cohortDateRange: prepareDateRangeValueObject(params.cohortDateRange),
      revenueDateRange: prepareDateRangeValueObject(params.revenueDateRange),
      probabilisticAttribution: params.probabilisticAttribution,
      dimensionsFilters,
    });
  };

export const getTreeData =
  (parentGroups: ParentGroup[]): AppThunk<Promise<TreeDataRow[]>> =>
  (_dispatch, getState) => {
    const state = getState();
    const config = selectConfig(state);
    const sorting = selectConfigSorting(state);
    const filters = selectFilters(state);
    const allDimensions = selectMetaAllDimensions(state);
    const allMetrics = selectMetaAllMetrics(state);
    const { dimensionsFilters, metricsFilters } = prepareFilters(
      filters,
      allMetrics,
      allDimensions,
    );

    return CalendarInsightsAPI.getTreeData({
      dimensions: [config.dimensions[parentGroups.length]],
      metrics: config.metrics,
      cohortDateScale: config.cohortDateScale,
      cohortDateRange: prepareDateRangeValueObject(config.cohortDateRange),
      revenueDateScale: config.revenueDateScale,
      revenueDateRange: prepareDateRangeValueObject(config.revenueDateRange),
      sort: sorting,
      probabilisticAttribution: config.probabilisticAttribution,
      dimensionsFilters,
      metricsFilters,
      parentGroups,
    }).then((response) => {
      return response.rows.map((row) => {
        return {
          ...row,
          path: [
            ...parentGroups.map((parentGroup) => {
              return parentGroup.value;
            }),
            row[config.dimensions[parentGroups.length]],
          ],
        } as TreeDataRow;
      });
    });
  };

export const getDataUpdateInfo = () => () => {
  return CalendarInsightsAPI.getDataUpdateInfo();
};

interface GetNumberFilterParams {
  filterName: string;
  dimensions: string[];
  filters: Filters;
  allMetrics: string[];
  allDimensions: string[];
  cohortDateRange: SerializedDateRangeValueObject;
  cohortDateScale: DateScale;
  revenueDateRange: SerializedDateRangeValueObject;
  revenueDateScale: DateScale;
  probabilisticAttribution: boolean;
}

export const getNumberFilterParams =
  (params: GetNumberFilterParams): AppThunk<Promise<NumberFilterParams>> =>
  (_dispatch, getState) => {
    const state = getState();
    const isTreeView = selectIsTreeDataGridView(state);
    const { dimensionsFilters, metricsFilters } = prepareFilters(
      getApplicableFilters(params.filters, params.filterName),
      params.allMetrics,
      params.allDimensions,
    );

    return CalendarInsightsAPI.getNumberFilterParams({
      dimensions: params.dimensions,
      filterName: params.filterName,
      dimensionsFilters,
      metricsFilters,
      cohortDateRange: prepareDateRangeValueObject(params.cohortDateRange),
      cohortDateScale: params.cohortDateScale,
      revenueDateRange: prepareDateRangeValueObject(params.revenueDateRange),
      revenueDateScale: params.revenueDateScale,
      probabilisticAttribution: params.probabilisticAttribution,
      groupedData: isTreeView,
    });
  };

interface GetStringFilterParams {
  filterName: string;
  filters: Filters;
  allMetrics: string[];
  allDimensions: string[];
  cohortDateRange: SerializedDateRangeValueObject;
  revenueDateRange: SerializedDateRangeValueObject;
}

export const getStringFilterParams =
  (params: GetStringFilterParams): AppThunk<Promise<StringFilterParams>> =>
  () => {
    const { dimensionsFilters } = prepareFilters(
      getApplicableFilters(params.filters, params.filterName),
      params.allMetrics,
      params.allDimensions,
    );

    return CalendarInsightsAPI.getStringFilterParams({
      filterName: params.filterName,
      dimensionsFilters,
      cohortDateRange: prepareDateRangeValueObject(params.cohortDateRange),
      revenueDateRange: prepareDateRangeValueObject(params.revenueDateRange),
    });
  };

// enforced to move out of slice declaration due to type errors
export const getMetricsMinMax =
  (params: GetMetricsMinMaxParams): AppThunk<Promise<MinMaxData>> =>
  (_dispatch, getState) => {
    if (params.dimensions.length === 0 && params.metrics.length === 0) {
      return Promise.resolve({});
    }

    const state = getState();
    const isTreeView = selectIsTreeDataGridView(state);
    const allDimensions = selectMetaAllDimensions(state);
    const allMetrics = selectMetaAllMetrics(state);
    const { dimensionsFilters, metricsFilters } = prepareFilters(
      params.filters,
      allMetrics,
      allDimensions,
    );

    return CalendarInsightsAPI.getMetricsMinMax({
      metricNames: params.metrics,
      selectedDimensions: params.dimensions,
      dimensionsFilters,
      metricsFilters,
      cohortDateRange: prepareDateRangeValueObject(params.cohortDateRange),
      cohortDateScale: params.cohortDateScale,
      revenueDateRange: prepareDateRangeValueObject(params.revenueDateRange),
      revenueDateScale: params.revenueDateScale,
      probabilisticAttribution: params.probabilisticAttribution,
      groupedData: isTreeView,
    });
  };

export const {
  updateColumns,
  reorderDimensions,
  reorderMetrics,
  updateSorting,
  updateCohortDateRange,
  updateCohortDateScale,
  updateRevenueDateRange,
  updateRevenueDateScale,
  updateProbabilisticAttribution,
  updateFilters,
  resetFilters,
  updateConfig,
  reset,
  updateConfigRowHeight,
  updateConfigColumnsWidth,
  updateDataGridViewType,
  updateConfigMetricsColoring,
} = calendarInsightsSlice.actions;
export const {
  selectMetaIsFailed,
  selectMetaIsLoaded,
  selectMetaIsLoading,
  selectConfigDimensions,
  selectConfigMetrics,
  selectMetaAllDimensions,
  selectMetaAllMetrics,
  selectMetaConfig,
  selectProbabilisticAttribution,
  selectConfig,
  selectFilters,
  selectConfigSorting,
  selectCohortDateScale,
  selectRevenueDateScale,
  selectConfigRowHeight,
  selectConfigColumnsWidth,
  selectCohortDateRange,
  selectRevenueDateRange,
  selectAdvaceSortableOptions,
  selectConfigDataGridViewType,
  selectIsTreeDataGridView,
  selectConfigMetricsColoring,
} = calendarInsightsSlice.selectors;
