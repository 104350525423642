import { useEffect, useRef, useState } from 'react';

interface DataFetcherInfo<T> {
  data?: T;
  loading: boolean;
  error?: unknown;
  retry: () => void;
}

export const useDataFetcher = <T>(
  fetcher: () => Promise<T>,
): DataFetcherInfo<T> => {
  const [data, setData] = useState<T | undefined>(undefined);
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState<unknown>();
  const latestFetcher = useRef<() => Promise<T>>();

  const requestData = () => {
    setLoading(true);
    fetcher()
      .then((response) => {
        if (fetcher !== latestFetcher.current) {
          return;
        }

        setData(response);
      })
      .catch((error) => {
        if (fetcher !== latestFetcher.current) {
          return;
        }

        setError(error);
      })
      .finally(() => {
        if (fetcher !== latestFetcher.current) {
          return;
        }

        setLoading(false);
      });
  };

  useEffect(
    () => {
      latestFetcher.current = fetcher;
      requestData();

      return () => {
        setData(undefined);
      };
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [fetcher],
  );

  return {
    data,
    loading,
    error,
    retry: requestData,
  };
};
