import MoreVertIcon from '@mui/icons-material/MoreVert';
import type { ButtonProps } from '@mui/material';
import {
  Button as MUIButton,
  IconButton,
  Menu,
  MenuItem as MUIMenuItem,
  Tooltip,
  Box,
  Typography,
  Chip,
  LinearProgress,
} from '@mui/material';
import { styled } from '@mui/material/styles';
import type { MouseEvent } from 'react';
import { useState } from 'react';
import { Analytics, formatDate, formatTime } from '@/shared/lib';
import type { SavedViewRecord } from '../../model/types';
import { useSavedViewsContext } from '../SavedViewsContext/SavedViewsContext';

const Item = styled((props: ButtonProps) => {
  return <MUIButton fullWidth component="li" {...props} />;
})(({ theme }) => ({
  color: theme.palette.text.primary,
  fontWeight: 'inherit',
  textTransform: 'none',
  padding: `${theme.spacing(2)} 0 ${theme.spacing(2)} ${theme.spacing(0.5)}`,
  display: 'flex',
  gap: theme.spacing(2),
  alignItems: 'start',
  borderRadius: 0,
  borderBottom: `1px solid ${theme.palette.divider}`,
}));
const MenuItem = styled(MUIMenuItem)(({ theme }) => ({
  gap: theme.spacing(1),
  fontSize: '14px',
}));
const ActiveDot = styled('span')(({ theme }) => ({
  display: 'inline-block',
  width: 6,
  height: 6,
  backgroundColor: theme.palette.info.main,
  borderRadius: '50%',
}));
const ActiveLabel = styled('span')(({ theme }) => ({
  display: 'flex',
  gap: theme.spacing(1),
  alignItems: 'center',
  fontSize: '14px',
  lineHeight: '22px',
}));

interface Props {
  view: SavedViewRecord;
}

export function ViewItem({ view }: Props) {
  const {
    appliedViewUuid,
    applyView,
    updateView,
    deleteView,
    setAsDefault,
    unsetAsDefault,
  } = useSavedViewsContext();
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);
  const open = Boolean(anchorEl);
  const handleClick = (event: MouseEvent<HTMLElement>) => {
    event.stopPropagation();
    setAnchorEl(event.currentTarget);
  };
  const handleClose = (event: MouseEvent) => {
    event.stopPropagation();
    setAnchorEl(null);
  };
  const handleUpdateView = () => {
    updateView(view.uuid);
    Analytics.sendFeatureUsage('saved_views', 'update_view');
  };
  const handleDeleteView = () => {
    deleteView(view.uuid);
    Analytics.sendFeatureUsage('saved_views', 'delete_view');
  };
  const handleViewApply = () => {
    applyView(view);
    Analytics.sendFeatureUsage('saved_views', 'apply_view');
  };
  const handleSetAsDefault = () => {
    setAsDefault(view.uuid);
    Analytics.sendFeatureUsage('saved_views', 'set_as_default');
  };
  const handleUnsetAsDefault = () => {
    unsetAsDefault(view.uuid);
    Analytics.sendFeatureUsage('saved_views', 'unset_as_default');
  };
  const dateLabel = formatDate(view.updatedAt);
  const timeLabel = formatTime(view.updatedAt).slice(0, -3);
  const isDefault = view.savedView.isDefault;
  const active = view.uuid === appliedViewUuid;
  const name = view.savedViewName;

  return (
    <Item key={view.uuid} onClick={handleViewApply}>
      <Box sx={{ flexGrow: 1, display: 'flex', gap: 2 }}>
        <Box
          sx={{
            flex: 1,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-start',
            gap: 0.5,
          }}
        >
          <Tooltip title={name}>
            <Typography
              variant="subtitle2"
              sx={{
                flexGrow: 1,
                fontWeight: 400,
                lineHeight: '24px',
                maxWidth: 170,
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {name}
            </Typography>
          </Tooltip>
          {isDefault && (
            <Chip
              size="small"
              label="Default"
              sx={{ fontSize: '0.75rem', lineHeight: '1.33', height: 20 }}
            />
          )}
        </Box>
        <Box
          sx={{
            flexShrink: 0,
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'flex-end',
            gap: 0.5,
            fontSize: '12px',
            lineHeight: '16px',
          }}
        >
          {active && (
            <ActiveLabel>
              <ActiveDot /> Current View
            </ActiveLabel>
          )}
          <span>Last Modified Date / Time</span>
          <span>
            {dateLabel} / {timeLabel}
          </span>
        </Box>
      </Box>
      <IconButton size="small" onClick={handleClick}>
        <MoreVertIcon />
      </IconButton>
      <Menu
        anchorEl={anchorEl}
        open={open}
        transformOrigin={{ horizontal: 'right', vertical: 'top' }}
        anchorOrigin={{ horizontal: 'right', vertical: 'bottom' }}
        onClose={handleClose}
        onClick={handleClose}
      >
        {isDefault ? (
          <MenuItem onClick={handleUnsetAsDefault}>Unset as default</MenuItem>
        ) : (
          <MenuItem onClick={handleSetAsDefault}>Set as default</MenuItem>
        )}
        <MenuItem onClick={handleUpdateView}>Save</MenuItem>
        <MenuItem onClick={handleDeleteView}>Delete</MenuItem>
      </Menu>
      {view.updating && (
        <LinearProgress
          color="info"
          sx={{ position: 'absolute', bottom: 0, left: 0, right: 0 }}
        />
      )}
    </Item>
  );
}
