import { Backdrop } from '@mui/material';
import { useEffect } from 'react';
import { Navigate, Outlet } from 'react-router-dom';
import { getAnalyticsId } from '@/entities/analytics';
import {
  selectIsAuthorized,
  selectIsSessionLoading,
  selectIsSessionRequested,
  setupUser,
} from '@/entities/session';
import { ROUTES } from '@/shared/constants';
import { Analytics, Monitoring } from '@/shared/lib';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import { Spinner } from '@/shared/ui';

export function AuthGuard() {
  const dispath = useAppDispatch();
  const isSessionRequested = useAppSelector(selectIsSessionRequested);
  const isSessionLoading = useAppSelector(selectIsSessionLoading);
  const isAuthorized = useAppSelector(selectIsAuthorized);

  useEffect(() => {
    if (isSessionRequested) {
      return;
    }

    dispath(setupUser())
      .unwrap()
      .then((user) => {
        if (!user) {
          return;
        }

        Monitoring.setUser({ id: user.id, email: user.email });
        getAnalyticsId(user.id).then((id) => {
          Analytics.setUserId(id);
        });
      });
  }, [isSessionRequested, dispath]);

  if (isSessionLoading || !isSessionRequested) {
    return (
      <Backdrop open>
        <Spinner />
      </Backdrop>
    );
  } else if (!isAuthorized) {
    return <Navigate to={ROUTES.signin} />;
  }

  return <Outlet />;
}
