export interface SavedViewDTO {
  created_at: string;
  uuid: string;
  saved_view: {
    storage: SavedViewStorage;
    snapshot: unknown;
    isDefault: boolean;
  };
  saved_view_name: string;
  updated_at: string;
  user_id: string;
}

export enum SavedViewStorage {
  cohortMarketingPerformance = 'cohortMarketingPerformance',
  calendarInsights = 'calendarInsights',
  chartsView = 'chartsView',
}
