import * as Sentry from '@sentry/react';
import React from 'react';
import {
  matchRoutes,
  useLocation,
  useNavigationType,
  createRoutesFromChildren,
  createBrowserRouter,
} from 'react-router-dom';
import { isDevelopment } from '../utils';

class AbstractMonitoring {
  static get createBrowserRouter(): typeof createBrowserRouter {
    throw new Error('No implementation');
  }

  static init(): void {
    throw new Error('No implementation');
  }

  static setUser(_user: Sentry.User): void {
    throw new Error('No implementation');
  }

  static captureError(..._args: unknown[]) {
    throw new Error('No implementation');
  }

  static showReportDialog(): void {
    throw new Error('No implementation');
  }
}

class NoMonitoring extends AbstractMonitoring {
  static get createBrowserRouter() {
    return createBrowserRouter;
  }

  static init() {}

  static captureError(): void {}

  static setUser(_user: Sentry.User): void {}

  static showReportDialog(): void {}
}

class SentryMonitoring extends AbstractMonitoring {
  private static _createBrowserRouter = createBrowserRouter;

  static init() {
    Sentry.init({
      dsn: 'https://fc39779058c3095e9edad44366b5405c@o4508379244199936.ingest.us.sentry.io/4508381801086976',
      integrations: [
        Sentry.replayIntegration(),
        Sentry.reactRouterV6BrowserTracingIntegration({
          useEffect: React.useEffect,
          useLocation,
          useNavigationType,
          createRoutesFromChildren,
          matchRoutes,
        }),
        Sentry.feedbackIntegration({
          colorScheme: 'light',
          autoInject: false,
          submitButtonLabel: 'Send Feedback',
          showName: false,
          formTitle: 'Send Feedback',
          messagePlaceholder: 'How can we make Campaignswell better for you?',
          useSentryUser: {
            email: 'email',
          },
        }),
      ],
      // Session Replay
      replaysSessionSampleRate: 0.01,
      replaysOnErrorSampleRate: 1.0, // If you're not already sampling the entire session, change the sample rate to 100% when sampling sessions where errors occur.
    });
    SentryMonitoring._createBrowserRouter =
      Sentry.wrapCreateBrowserRouter(createBrowserRouter);
  }

  static get createBrowserRouter() {
    return SentryMonitoring._createBrowserRouter;
  }

  static setUser(user: Sentry.User): void {
    Sentry.setUser(user);
  }

  static captureError(error: unknown): string {
    return Sentry.captureException(error);
  }

  static showReportDialog(): void {
    const feedback = Sentry.getFeedback();

    if (!feedback) {
      return;
    }

    feedback.createForm().then((form) => {
      form.appendToDom();
      form.open();
    });
  }
}

export const Monitoring = isDevelopment() ? NoMonitoring : SentryMonitoring;
