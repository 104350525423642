import isEqual from 'lodash.isequal';
import { useSnackbar } from 'notistack';
import type { ReactNode } from 'react';
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from 'react';
import { Monitoring } from '@/shared/lib';
import { useAppDispatch, useAppSelector } from '@/shared/model';
import type { SavedViewStorage } from '../../api/types';
import {
  createView,
  deleteView,
  selectViews,
  setAsDefault,
  setupViews,
  unsetAsDefault,
  updateViewSnapshot,
} from '../../model/slice';
import type { SavedViewRecord } from '../../model/types';

interface ContextState {
  appliedViewUuid: string;
  appliedViewName?: string;
  views: SavedViewRecord[];
  applyView: (view: SavedViewRecord) => void;
  createView: (name: string) => Promise<void>;
  updateView: (uuid: string) => void;
  deleteView: (uuid: string) => void;
  setAsDefault: (uuid: string) => void;
  unsetAsDefault: (uuid: string) => void;
}

const Context = createContext<ContextState | null>(null);
const NO_VIEW_APPLIED = '';

interface Props<T> {
  children: ReactNode;
  storage: SavedViewStorage;
  snapshotValidator: (
    v: unknown,
  ) => { success: true; data: T } | { success: false; error: unknown };
  currentViewState: unknown;
  onApply: (snapshot: unknown) => void;
}

export function SavedViewsProvider<T>({
  children,
  currentViewState,
  snapshotValidator,
  storage,
  onApply,
}: Props<T>) {
  const dispatch = useAppDispatch();
  const { enqueueSnackbar } = useSnackbar();
  const allViews = useAppSelector(selectViews);
  const views = useMemo<SavedViewRecord[]>(() => {
    return allViews
      .filter((view) => {
        return view.savedView.storage === storage;
      })
      .map((view) => {
        const parseResult = snapshotValidator(view.savedView.snapshot);
        const snapshot = parseResult.success ? parseResult.data : null;

        return {
          ...view,
          savedView: {
            ...view.savedView,
            snapshot,
          },
        };
      })
      .filter((view) => {
        return !!view.savedView.snapshot;
      });
  }, [allViews, storage, snapshotValidator]);
  const [appliedViewUuid, setAppliedViewUuid] = useState(NO_VIEW_APPLIED);
  const applyView = useCallback(
    (view: SavedViewRecord) => {
      if (view.uuid === appliedViewUuid) {
        return;
      }

      setAppliedViewUuid(view.uuid);
      onApply(view.savedView.snapshot);
    },
    [onApply, appliedViewUuid],
  );
  const appliedView = useMemo(() => {
    return views.find((view) => {
      return view.uuid === appliedViewUuid;
    });
  }, [views, appliedViewUuid]);

  useEffect(
    () => {
      dispatch(setupViews())
        .unwrap()
        .then((allSavedViews) => {
          const defaultView = allSavedViews.find((view) => {
            return (
              view.savedView.storage === storage && view.savedView.isDefault
            );
          });

          if (!defaultView) {
            return;
          }

          applyView(defaultView);
        });
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch],
  );

  useEffect(() => {
    if (appliedViewUuid === NO_VIEW_APPLIED) {
      return;
    }

    const appliedView = views.find((view) => {
      return view.uuid === appliedViewUuid;
    });

    if (
      !appliedView ||
      !isEqual(appliedView.savedView.snapshot, currentViewState)
    ) {
      setAppliedViewUuid(NO_VIEW_APPLIED);
    }
  }, [currentViewState, appliedViewUuid, views]);

  return (
    <Context.Provider
      value={{
        views,
        appliedViewUuid,
        appliedViewName: appliedView?.savedViewName,
        applyView,
        updateView: (uuid) => {
          dispatch(updateViewSnapshot(uuid, currentViewState)).then(() => {
            const appliedView = views.find((view) => {
              return view.uuid === appliedViewUuid;
            })!;

            setAppliedViewUuid(uuid);
            enqueueSnackbar(
              `"${appliedView.savedViewName}" successfully updated.`,
              {
                variant: 'success',
                autoHideDuration: 2000,
              },
            );
          });
        },
        createView: (name) => {
          return dispatch(
            createView({
              storage,
              name,
              snapshot: currentViewState,
            }),
          )
            .unwrap()
            .then((newView) => {
              setAppliedViewUuid(newView.uuid);
              enqueueSnackbar(`"${name}" successfully saved.`, {
                variant: 'success',
                autoHideDuration: 2000,
              });
            })
            .catch((error) => {
              Monitoring.captureError(error);
              enqueueSnackbar('Unable to save view. Please, try again.', {
                variant: 'error',
                autoHideDuration: 4000,
              });

              throw error;
            });
        },
        deleteView: (uuid) => {
          dispatch(deleteView(uuid))
            .unwrap()
            .then(() => {
              if (uuid === appliedViewUuid) {
                setAppliedViewUuid(NO_VIEW_APPLIED);
              }
            })
            .catch(() => {
              enqueueSnackbar('Unable to delete view. Please, try again.', {
                variant: 'error',
                autoHideDuration: 4000,
              });
            });
        },
        setAsDefault: (uuid) => {
          dispatch(setAsDefault(uuid)).catch(() => {
            enqueueSnackbar(
              'Unable to set view as default. Please, try again.',
              {
                variant: 'error',
                autoHideDuration: 4000,
              },
            );
          });
        },
        unsetAsDefault: (uuid) => {
          dispatch(unsetAsDefault(uuid)).catch(() => {
            enqueueSnackbar(
              'Unable to unset view as default. Please, try again.',
              {
                variant: 'error',
                autoHideDuration: 4000,
              },
            );
          });
        },
      }}
    >
      {children}
    </Context.Provider>
  );
}

export const useSavedViewsContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useSavedViewsContext hook used outside <SavedViewsProvider />',
    );
  }

  return context;
};
