import type { ReactNode } from 'react';
import { createContext, useContext, useMemo, useState } from 'react';
import type { StringFilterParams } from '../../model/types';
import { useFiltersParamsContext } from '../FiltersParamsContext/FiltersParamsContext';

interface ContextState {
  getOptionValue: (v: unknown) => string;
  filterQuery: string;
  filteredOptions: unknown[];
  setFilterQuery: (v: string) => void;
  getSelectAllValue: () => string[];
  getClearAllValue: () => string[];
  isSelectAllDisabled: (v: string[]) => boolean;
  isClearAllDisabled: (v: string[]) => boolean;
}

const Context = createContext<ContextState | null>(null);

interface Props {
  id: string;
  value: string[];
  getOptionValue: (v: unknown) => string;
  children: ReactNode;
}

export const FilterSelectionControlsProvider = ({
  id,
  value,
  getOptionValue,
  children,
}: Props) => {
  const { paramsData } = useFiltersParamsContext();
  const [filterQuery, setFilterQuery] = useState('');
  const filteredOptions = useMemo(() => {
    const options: unknown[] =
      (paramsData[id]?.params as StringFilterParams)?.options || [];
    const preparedQuery = filterQuery.toLowerCase();

    return (options || []).filter((option) => {
      return getOptionValue(option).toLowerCase().includes(preparedQuery);
    });
  }, [filterQuery, paramsData[id]]);

  return (
    <Context.Provider
      value={{
        filteredOptions,
        filterQuery,
        setFilterQuery,
        getSelectAllValue: () => {
          return [
            ...new Set([
              ...value,
              ...filteredOptions.map((option) => {
                return getOptionValue(option);
              }),
            ]),
          ];
        },
        getClearAllValue: () => {
          const newValue = new Set(value);
          const clearValue = new Set(
            filteredOptions.map((option) => {
              return getOptionValue(option);
            }),
          );

          return [...newValue.difference(clearValue)];
        },
        isClearAllDisabled: (internalValue) => {
          const intersection = new Set(internalValue).intersection(
            new Set(
              filteredOptions.map((option) => {
                return getOptionValue(option);
              }),
            ),
          );

          return intersection.size === 0;
        },
        isSelectAllDisabled: (internalValue) => {
          return new Set(internalValue).isSupersetOf(
            new Set(
              filteredOptions.map((option) => {
                return getOptionValue(option);
              }),
            ),
          );
        },
        getOptionValue,
      }}
    >
      {children}
    </Context.Provider>
  );
};

export const useFilterSelectionControlsContext = () => {
  const context = useContext(Context);

  if (!context) {
    throw new Error(
      'useFilterSelectionControlsContext hook used outside <FilterSelectionControlsProvider />',
    );
  }

  return context;
};
